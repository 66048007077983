@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
}

/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
} */

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

a {
  color: initial;
}

a:hover {
  text-decoration: none;
  color: initial;
}

button {
  border-radius: 5px !important;
  font-weight: 600 !important;
}

::-moz-selection {
  color: #fff !important;
  background: #31b5c4 !important;
}

::selection {
  color: #fff !important;
  background: #31b5c4 !important;
}

.main-heading {
  font-size: 28px;
  font-weight: 600;
}

.main-desc {
  color: #42464a;
  margin-bottom: 45px;
  max-width: 500px;
  font-size: 18px;
}

.main-subheading {
  color: #787887;
  font-size: 16px;
}

.section-heading {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 45px;
}

.card-img-top,
.packages .card-img-top img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}

.packages .card-img-top img {
  height: 150px;
}

.card {
  border-radius: 10px;
  border: 1px solid #f0f0f5;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

option {
  font-size: 20px;
}

.font-semibold {
  font-weight: 600;
}

.font-20 {
  font-size: 20px;
}

.pointer {
  cursor: pointer;
}

.text-failed {
  color: #ff4500;
}

/*=============================================================*/
/*========================== Navbar ===========================*/
/*=============================================================*/
.semi-nav {
  padding: 10px 0;
  background-color: #ebf3f5;
  color: #42464a;
  /* background-color: #31b5c4;
  color: #fff; */
}

.semi-nav .left {
  font-size: 15px;
}

.semi-nav .left a {
  font-weight: 600;
}

.semi-nav .left a img {
  width: 15px;
}

.semi-nav .left img.promotion {
  width: 19px;
}

.semi-nav .left img.right-arrow {
  width: 15px;
}

.semi-nav .right .call {
  font-size: 15px;
  /* animation: hoverasdsadsad 0.7s linear 0s infinite alternate; */
}

.semi-nav .right {
  align-items: center;
}

.semi-nav .left .call a {
  font-size: 18px;
  font-weight: bold;
  color: #42464a;
  /* color: #fff; */
}

@keyframes hoverasdsadsad {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.07);
  }
}

.semi-nav .right .language {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.navbar {
  padding: 15px 0;
  background: #fff;
}

.navbar .container.main-con {
  position: relative;
}

nav.navbar .navbar-brand img {
  width: 42px;
}
 nav.navbar .navbar-brand .mobile-logo{
  display: none;
 }

nav.navbar .navbar-brand {
  margin-right: 50px;
  padding-top: 0;
  padding-bottom: 0;
}

nav.navbar .navbar-nav a {
  font-size: 15px;
  font-weight: 600;
  color: #000 !important;
}

nav.navbar .navbar-nav a:not(:last-child) {
  margin-right: 35px;
}

nav.navbar .navbar-nav a.nav-link {
  background: #fff;
  padding: 8px 15px;
  border-radius: 18px;
}

nav.navbar .navbar-nav a.active {
  background: #ebf3f5;
  padding: 8px 15px;
  border-radius: 18px;
}

nav.navbar .right .buttons {
  margin: 0 45px;
  display: flex;
  align-items: center;
}

nav.navbar .right .buttons .btn {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 95px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
}

nav.navbar .right .buttons .login {
  border: 1px solid #f0f0f5;
  color: #787887 !important;
}

nav.navbar .right .buttons .signup {
  color: #fff !important;
}

nav.navbar .right .buttons .user-det {
  position: relative;
}

nav.navbar .right .buttons .user-det ul {
  position: absolute;
  background: #fff;
  border: 1px solid #c3c3c3;
  z-index: 11;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  top: 0;
  border-radius: 3px;
  width: 165px;
}

nav.navbar .right .buttons .user-det ul.active {
  visibility: visible;
  opacity: 1;
  top: 28px;
}

nav.navbar .right .buttons .user-det ul li {
  padding: 6px 10px;
  cursor: pointer;
  transition: all 0.5s;
  color: #787887;
  font-size: 15px;
}

nav.navbar .right .buttons .user-det ul li:not(:first-child) {
  border-top: 1px solid #c3c3c3;
}

nav.navbar .right .buttons .user-det ul li:hover {
  background: #31b5c456;
  transition: all 0.3s;
}

nav.navbar .right .buttons .user-det ul li a {
  color: #787887 !important;
  display: block;
}

nav.navbar .cart img {
  height: 36px;
  cursor: pointer;
}

.navbar .right .location {
  display: flex;
  align-items: center;
}

.navbar .right .location .dropdown {
  margin-left: 8px;
}

.navbar .right .location .dropdown h5 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 700;
}

.navbar .right .location .dropdown select {
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 600;
  color: #787887;
  background: transparent;
}

/* .navbar .right .location.user {
  margin-right: 45px;
} */

.navbar .right .location .dropdown .options-select {
  position: relative;
}

.navbar .right .location .dropdown .options-select p {
  font-size: 15px;
  font-weight: 600;
  color: #787887;
  background: transparent;
  cursor: pointer;
  padding-left: 3px;
}

.navbar .right .location .dropdown .options-select i.drop-icon {
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 15px;
  color: #787887;
  cursor: pointer;
}

.navbar .right .location .dropdown .options-select ul {
  position: absolute;
  background: #fff;
  border: 1px solid #c3c3c3;
  z-index: 11;
  transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  top: 0;
  border-radius: 3px;
}

.navbar .right .location .dropdown .options-select ul.active {
  visibility: visible;
  opacity: 1;
  top: 23px;
}

.navbar .right .location .dropdown .options-select ul li {
  padding: 2px 10px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: baseline;
  color: #787887;
  font-size: 15px;
}

.navbar .right .location .dropdown .options-select ul li i {
  margin-right: 5px;
}

.navbar .right .location .dropdown .options-select ul li i.self {
  margin-right: 8px;
  padding-left: 3px;
}

.navbar .right .location .dropdown .options-select ul li:hover {
  background: #31b5c456;
  transition: all 0.3s;
}

/*=============================================================*/
/*====================== Mobile Semi Nav ======================*/
/*=============================================================*/
.mobile-semi-nav {
  display: block;
}

.mobile-semi-nav p {
  font-size: 12px;
  margin: 0;
}

.mobile-semi-nav p.offer {
  padding-top: 10px;
  margin-bottom: 5px;
}

.mobile-semi-nav p.number {
  padding-bottom: 10px;
}

.mobile-semi-nav p a {
  font-weight: 600;
  margin-right: 6px;
  color: #fff;
}

.mobile-semi-nav p a img {
  width: 12px;
}

.mobile-semi-nav .search {
  padding: 15px 0;
}

.mobile-semi-nav .search .input-group {
  border: 1px solid #dbdcdf;
  border-radius: 5px;
  justify-content: space-between;
}

.mobile-semi-nav .search .input-group img {
  width: 20px;
}

.mobile-semi-nav .search .input-group .img {
  padding: 10px 15px;
  border: none;
  outline: none;
  background-color: #fff;
}

.mobile-semi-nav .search input {
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 14px;
  width: 85%;
}

.mobile-semi-nav .search input::placeholder {
  color: #e4e4e4;
}

/*=============================================================*/
/*========================= Showcase ==========================*/
/*=============================================================*/
.showcase {
  background-color: #f0f0f5;
  padding: 85px 0;
  background-image: url("./assets/img/GirlPhone.png");
  /* background-image: url('./assets/img/homepage-banner.png'); */
  background-repeat: no-repeat;
  /* background-size: 50%; */
  /* background-size: calc(100% - 50%); */
  /* background-position: right 100px bottom 0px; */
  background-position: calc(100% - 20%) calc(100% - 0px);
}

.showcase h5 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 1.3;
}

.showcase p {
  margin-bottom: 25px;
}

.showcase button,
.showcase a {
  font-size: 18px;
  font-weight: 600;
  padding: 16px 45px;
}

/*=============================================================*/
/*========================= Category ==========================*/
/*=============================================================*/
.category {
  padding: 100px 0;
}

.category .description {
  margin-bottom: 30px;
}

.category .description h5 {
  margin-bottom: 8px;
}

.container.carousel-container {
  padding: 0;
}

.test .abc .single {
  margin: 0 15px;
}

.test .item {
  background: #f0f0f5;
  padding: 55px 45px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.test .item.allergy {
  /* padding: 47px 52px; */
  /* padding: 30px 34px 30px 30px; */
  padding: 40px;
  cursor: pointer;
}

.test .item.allergy a img {
  border-radius: 50%;
}

.test .item.cancer {
  padding: 49px 52px;
}

.test p.name {
  font-weight: 700;
  font-size: 15px;
}

.cate-page .hero-mobile .react-multi-carousel-dot button {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  width: 12px;
  height: 12px;
  border-radius: 50% !important;
  margin-right: 8px;
}

.cate-page .hero-mobile .react-multi-carousel-dot--active button {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

/*=============================================================*/
/*========================= Checkups ==========================*/
/*=============================================================*/
.checkups {
  padding-bottom: 120px;
}

.checkups .description {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkups .description form {
  width: 467px;
}

.checkups .description form input {
  padding: 30px 25px;
  font-size: 16px;
  border: none;
  background: #f0f0f5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.checkups .description form button {
  border: none;
  padding: 0 25px;
  border-radius: 5px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: #f0f0f5;
}

.checkups .description form button i {
  color: #31b5c4;
  font-size: 18px;
}

.checkups .filter .border {
  border: 2px solid #f0f0f5 !important;
  border-radius: 10px;
  padding: 22px 35px;
  margin-bottom: 45px;
}

.checkups .filter .border .rogw {
  justify-content: space-between;
  display: flex;
}

.checkups .filter .border p,
.checkups .filter .border a {
  font-size: 16px;
  font-weight: 600;
}

.checkups .filter .filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkups .filter .filters .left {
  align-items: center;
  display: flex;
}

.checkups .filter .filters .left .categories,
.checkups .filter .filters .left .citis {
  margin-right: 15px;
}

.checkups .filter .filters .left .labs {
  margin-right: 80px;
}

.checkups .filter select {
  border: none;
  outline: none;
  background: #f0f0f5;
  width: 154px;
  height: 50px;
  padding: 14px 22px;
  border-radius: 5px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 9px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: 600;
}

.checkups .filter p {
  margin-right: 35px;
}

.checkups .filter .sort {
  display: flex;
  align-items: center;
  padding-right: 0;
  padding-left: 0;
  padding-left: 0;
  border-left: 2px solid #f0f0f5;
}

.checkups .filter .sort p {
  margin-left: 35px;
}

.checkups .items .single-item .card {
  border-radius: 10px;
  border: 1px solid #f0f0f5;
  margin-bottom: 30px;
}

.checkups .items .single-item .card .card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.checkups .items .single-item .card .card-body {
  padding: 15px 20px;
}

.checkups .items .single-item .card .card-body h5 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  /* min-height: 54px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkups .items .single-item .card .card-body p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 10px;
  min-height: 54px;
}

.checkups .items .single-item .card .card-body .places {
  display: flex;
}

.checkups .items .single-item .card .card-body .hospitals {
  min-height: 65px;
}

.checkups .items .single-item .card .card-body .places p {
  background-color: #f0f0f5;
  color: #2d2d32;
  font-weight: 600;
  padding: 4px 0;
  width: 70px;
  font-size: 12px;
  margin-bottom: 20px;
  min-height: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-transform: uppercase;
}

.checkups .items .single-item .card .card-body .places p:first-child {
  margin-right: 5px;
}

.checkups .items .single-item .card .card-body .hospitals img {
  width: 70px;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  padding: 12px 15px 11px 17px;
}

.checkups .items .single-item .card .card-body .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.checkups .items .single-item .card .card-body .footer .price {
  font-weight: 700;
  font-size: 15px;
  color: #2d2d32;
  min-height: auto;
}

.checkups .items .single-item .card .card-body .footer .from {
  min-height: auto;
  margin-bottom: 0;
}

.checkups .items .single-item .card .card-body .footer button {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 17px;
  background-color: #ebf3f5;
  color: #31b5c4;
  display: flex;
  align-items: center;
}

.checkups .items .single-item .card .card-body .footer button img {
  width: 10px;
  margin-right: 10px;
}

.checkups .items .view-more button {
  background: #f0f0f5;
  font-size: 15px;
  font-weight: 600;
  padding: 11px 25px;
  margin-top: 45px;
}

/*=============================================================*/
/*========================= Services ==========================*/
/*=============================================================*/
.services {
  background: #f0f0f5;
  padding: 75px 0;
}

/* .services .section-heading {
  margin-bottom: 8px;
} */

.services .icon {
  background: #f5f5f5;
  padding: 28px 34px;
  border-radius: 50%;
  display: inline-block;
}

/* .services .icon img {
  width: 30px;
} */

/* .services .second .icon img {
  width: 40px;
} */

.services .second .icon {
  padding: 29px 32px;
}

.services .details h5 {
  margin-top: 18px;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 600;
}

.services .details p {
  color: #42464a;
  font-size: 16px;
}

/*=============================================================*/
/*========================== Homepage =========================*/
/*=============================================================*/

/*============================ Hero ===========================*/
.homepage .order-find {
  display: none;
}
.homepage .hero {
  padding-top: 75px;
  padding-bottom: 120px;
  background-color: #f8f9fa;
}

.homepage .hero h5 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.3;
}

.homepage .hero p {
  color: #787887;
  font-size: 18px;
  margin-bottom: 30px;
}

.homepage .hero .steps .one,
.homepage .hero .steps .two {
  display: flex;
  align-items: center;
}

.homepage .hero .steps {
  margin-bottom: 30px;
}

.homepage .hero .steps .one {
  margin-right: 40px;
}

.homepage .hero .steps .one .details,
.homepage .hero .steps .two .details {
  margin-left: 15px;
}

.homepage .hero .steps h5 {
  font-size: 15px;
  margin-bottom: 2px;
}

.homepage .hero .steps h5 span {
  font-weight: 700;
}

.homepage .hero .steps h5 {
  font-weight: 600;
}

.homepage .hero .steps p {
  font-size: 12px;
  color: #787887;
}

.homepage .hero .buttons button,
.homepage .hero .buttons a {
  padding: 16px 30px;
  font-size: 18px;
  border-radius: 5px;
  width: 210px;
  /* border: 1px solid #3c4043;
  background: #fff; */
}

.homepage .hero .buttons a.phone {
  background-color: #e2e6ea;
  border: 1px solid #e2e6ea;
}

.homepage .hero .buttons a.phone:hover {
  background-color: #ced5dc;
  border: 1px solid #ced5dc;
}

.homepage .hero .buttons .order,
.homepage .hero a .order {
  font-weight: 600;
  margin-right: 15px;
}

.homepage .hero .buttons .test,
.homepage .hero a .test {
  border: 1px solid #31b5c4;
}

.homepage .hero .right {
  display: flex;
  justify-content: flex-end;
}

.homepage .hero .right img {
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.homepage .hero .right .react-multi-carousel-dot button {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  width: 16px;
  height: 16px;
  border-radius: 50% !important;
  margin-right: 12px;
}

.homepage .hero .right .react-multi-carousel-dot--active button {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.homepage .hero .right .react-multi-carousel-dot-list {
  bottom: 10px;
}

.homepage .hero-mobile .react-multi-carousel-dot button {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  width: 12px;
  height: 12px;
  border-radius: 50% !important;
  margin-right: 8px;
}

.homepage .hero-mobile .react-multi-carousel-dot--active button {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

.homepage .hero-mobile .react-multi-carousel-dot-list {
  bottom: 5px;
}

.homepage .hero .right .image {
  width: 387px;
  height: 387px;
  position: relative;
  margin-left: auto;
}

.homepage .hero .right .image img {
  width: 100%;
  border-radius: 50%;
}

.homepage .hero .right .image .overlay {
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(49, 181, 196, 0.1), #31b5c4);
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 50%;
}

.homepage .hero .right .image .caption {
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
}

.homepage .hero .right .image .caption .title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 2px;
  color: #fff;
  /* text-align: center; */
}

.homepage .hero .right .image .caption .description {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0;
}

.homepage .hero .right .image .caption img {
  border-radius: 0%;
  width: 23px;
  margin-right: 10px;
}

/*======================= Counter =======================*/
.homepage .counter {
  background: #ebf3f5;
  padding: 33px 0;
}

.homepage .counter h5 {
  color: #1c1c1c;
  font-size: 30px;
  margin-bottom: 4px;
  font-weight: 600;
}
.homepage .counter p {
  font-size: 18px;
  margin-bottom: 0;
}

/* .homepage .counter .first {
  text-align: center;
} */

.homepage .counter .first,
.homepage .counter .second,
.homepage .counter .third {
  padding: 25px 0;
}

.homepage .counter .div {
  width: 1px;
  height: 100%;
  background: #d0d9d7;
}

.homepage .counter .content {
  display: flex;
  justify-content: space-evenly;
}

/*======================= Partners =======================*/
.homepage .partners {
  padding: 100px 0 70px;
}

.homepage .partners .section-heading {
  margin-bottom: 8px;
}

.homepage .partners .main {
  background: #f8f8f8;
  border-radius: 10px;
  padding: 25px 0;
}

.homepage .partners .main img {
  height: 100px;
  display: block;
  margin: 0 auto;
}

.homepage .partners .single {
  margin-bottom: 30px;
}

/*======================= Achievement =======================*/
.global.achievement iframe {
  border-radius: 10px;
}

.global.achievement .video {
  padding-right: 25px;
}

.global.achievement .video img {
  border-radius: 10px;
}

.global.achievement .details {
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.global.achievement .details p.content {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
}

.global.achievement .only-content.details p.content,
.global.achievement .only-content.details p.name,
.global.achievement .only-content.details p.title {
  text-align: center;
}

.global.achievement .only-content.details .icon img {
  display: block;
  margin: 0 auto;
}

.global.achievement .details p.name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.global.achievement .details p.title {
  margin-bottom: 0;
}

/*======================= Business Partner =======================*/
.homepage .business-partner {
  background: #f8f9fa;
  padding-bottom: 70px;
}

.homepage .business-partner .img {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  margin: 0 15px;
}

.homepage .business-partner img {
  width: 125px;
  /* height: 75px; */
}

.homepage .business-partner .react-multiple-carousel__arrow--left {
  left: 0;
}

.homepage .business-partner .react-multiple-carousel__arrow--right {
  right: 0;
}

.homepage .business-partner .react-multiple-carousel__arrow {
  box-shadow: 0 3px 15px #31b5c41a;
}

.homepage .business-partner .react-multiple-carousel__arrow--right,
.homepage .business-partner .react-multiple-carousel__arrow--left {
  top: 35%;
}

/*======================= Order Now =======================*/
.homepage .order-sec .first,
.homepage .order-sec .second {
  padding: 45px 50px;
  background: #e8f2ed;
  border-radius: 10px;
  /* background-image: url('./assets/img/new-girl.png');
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: calc(100% - 2%) calc(100% - 0px); */
}

.homepage .order-sec .second {
  background: #dce3e1;
  /* background-image: url('./assets/img/new-girl.png');
  background-repeat: no-repeat;
  background-size: 250px;
  background-position: calc(100% - 2%) calc(100% - 0px); */
}

.homepage .order-sec h5 {
  color: #3c4043;
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 600;
  width: 315px;
}

.homepage .order-sec p {
  color: #42464a;
  margin-bottom: 36px;
  font-size: 16px;
  width: 300px;
  min-height: 48px;
}

.homepage .order-sec a {
  font-size: 16px;
  padding: 12px 37px;
  border-radius: 5px;
  font-weight: 600;
}

/*====================== Request For Order ======================*/
.global.req-order {
  margin: 100px 0;
  background-color: #ebf3f5;
}

.global.req-order .container {
  padding: 56px 15px;
  /* background-image: url('./assets/img/new-girl.png');
  background-repeat: no-repeat;
  background-size: 210px;
  background-position: calc(100% - 0%) calc(100% - 0px); */
}

.global.req-order h5 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 8px;
}

.global.req-order p {
  font-size: 16px;
  margin-bottom: 20px;
}

.global.req-order input {
  border: none;
  outline: none;
  background: #fff;
  padding: 17px 20px;
  width: 315px;
  margin-right: 20px;
  border-radius: 5px;
  padding-bottom: 18px;
}

.global.req-order button {
  font-size: 16px;
  padding: 16px 51px;
  margin-bottom: 1px;
}

/*======================= Privilages =======================*/
.privilages .section-heading {
  margin-bottom: 8px;
}

.privilages .one,
.privilages .three {
  margin-bottom: 30px;
  padding-right: 0;
}

.privilages .two,
.privilages .four {
  padding-left: 25px;
  margin-bottom: 30px;
}

.privilages button {
  font-size: 16px;
  padding: 14px 40px;
  border: 1px solid #3c4043;
}

/*======================= Call For Order =======================*/
.call-for-order {
  background: #31b5c4;
  color: #fff;
  margin-bottom: 50px;
  border-radius: 5px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.call-for-order .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.call-for-order .left .icon i {
  font-size: 35px;
  margin-right: 15px;
}

.call-for-order .left .icon img {
  width: 50px;
  margin-right: 10px;
}

.call-for-order .left .text h5 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
}

.call-for-order .left .text p {
  font-size: 14px;
  margin: 0;
}

.call-for-order .right {
  border: 2px dashed #61cad6;
  padding: 11px 25px;
  border-radius: 5px;
  background-color: rgba(49, 181, 196, 0.5);
  border-style: solid;
}

.call-for-order .right a {
  font-size: 17px;
  display: flex;
  align-items: baseline;
  font-weight: 600;
}

.call-for-order .right a i {
  font-size: 15px;
  margin-right: 7px;
}

/*=========================== Why Us ==========================*/
.homepage .why-us.mobile {
  display: none;
}
.homepage .why-us {
  padding: 0 0 95px;
  /* padding: 75px 0 75px; */
  background: #ffff;
  /* background-color: #f0f0f5; */
}

.homepage .why-us .section-heading {
  margin-bottom: 8px;
}

.homepage .why-us.services .second .icon {
  padding: 28px 29px;
}

.homepage .why-us.services .third .icon {
  padding: 26px;
}

.homepage .why-us.services .first .icon {
  padding: 25px ​30px;
}

/* .homepage .why-us.services .third .icon i */

.homepage .why-us.services .details {
  max-width: 250px;
  margin: auto;
}

.homepage .why-us.services .details h5 {
  line-height: 1.4;
}

.homepage .why-us.services .details p {
  line-height: 1.5;
  width: 185px;
  margin: auto;
  /* font-weight: 100; */
}

/*============================ Tests ===========================*/
.homepage .tests {
  padding-bottom: 100px;
  /* padding-top: 100px; */
}

.homepage .tests .description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.homepage .tests .description a {
  font-size: 14px;
  font-weight: 600;
  padding: 11px 22px;
  border-radius: 5px;
}

.homepage .tests .card {
  border-radius: 10px;
  border: 1px solid #f0f0f5;
  margin-right: 7.5px;
  margin-left: 7.5px;
  /* border-bottom: 3px solid #f0f0f5; */
}

.homepage .tests .card .card-img-top {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.homepage .tests .card .card-body {
  padding: 15px 20px;
}

.homepage .tests .card .card-body h5 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.homepage .tests .card .card-body p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 20px;
  min-height: 54px;
}

.homepage .tests .card .card-body p.price {
  font-size: 15px;

  margin-bottom: 20px;
  min-height: auto;
}

.homepage .tests .card .card-body p.price span {
  font-weight: 700;
  color: #000;
}

.homepage .tests .card .card-body button {
  padding-top: 11px;
  padding-bottom: 11px;
  font-weight: 600;
  background: #f0f0f5;
  color: #787887;
  font-size: 14px;
}

/*=========================== Packages ==========================*/
.homepage .packages {
  padding-bottom: 100px;
}

.homepage .packages .description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.homepage .packages .description a {
  font-size: 14px;
  font-weight: 600;
  padding: 11px 22px;
  border-radius: 5px;
}

.test-package .card-img-top {
  position: relative;
}

.test-package .card-img-top .overlay {
  position: absolute;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0%), rgb(49 181 196 / 49%));
  width: 100%;
  height: 100%;
  top: 0;
}

.test-package .name {
  font-size: 18px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
}

.test-package .details {
  font-size: 14px;
  font-weight: 700;
  background: #f0f0f5;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 78px;
}

.homepage .packages .card {
  margin-right: 7.5px;
  margin-left: 7.5px;
}

.test-package .card-body {
  padding: 15px 20px;
}

.test-package .card-body img {
  border: 1px solid #f0f0f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-right: 10px;
  width: 80px;
}

.test-package .card-body .react-multi-carousel-item {
  margin-right: 10px;
}

.test-package .footer {
  padding: 15px 20px;
  border-top: 1px solid #f0f0f5;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.test-package .footer .price {
  font-size: 15px;
  font-weight: 600;
}
.test-package .footer .price span {
  font-weight: 700;
}

.test-package .footer button {
  font-size: 14px;
  padding: 10px 20px 9px;
}

.test-package .footer .old-price span {
  font-weight: 700;
}

.test-package .footer button {
  border-radius: 5px;
}

/*============================ Covid ==========================*/
.homepage .covid-19 {
  padding: 75px 0;
  color: #fff;
  /* color: #000; */
}

/*============================ faq ==========================*/
.global.faques {
  margin-bottom: 80px;
}

.global.faques h5 {
  margin-bottom: 8px;
}

.global.faques .accordion .single {
  border: 1px solid #dce3e1;
  border-radius: 10px;
  margin-bottom: 20px;
  /* padding: 27px 40px; */
}

.global.faques .accordion .single.active {
  background: #ebf3f5;
}

.global.faques .accordion button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 27px 40px;
}

.global.faques .accordion .active button {
  padding-bottom: 12px;
}

.global.faques .accordion button p.head {
  margin-bottom: 0;
  font-size: 18px;
  margin-left: 30px;
}

.global.faques .accordion div.desc {
  /* margin-left: 60px;
  margin-top: 12px; */
  margin-bottom: 0;
  padding-left: 90px;
  padding-right: 40px;
  padding-bottom: 27px;
}

.global.faques .accordion div.desc p {
  font-size: 16px;
}

.global.faques .accordion div.desc p.brek {
  margin-bottom: 10px;
}

/*============================ Achievements ==========================*/
.global.achievement {
  margin-top: 100px;
  padding: 70px 0;
  background: #f8f9fa;
}

.global.achievement .section-heading {
  margin-bottom: 8px;
}

.global.achievement .container.carousel-container {
  padding-bottom: 50px;
}

.global.achievement .react-multi-carousel-dot button {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  width: 16px;
  height: 16px;
  border-radius: 50% !important;
  margin-right: 12px;
}

.global.achievement .react-multi-carousel-dot--active button {
  background-color: #b0b0b0;
  border-color: #b0b0b0;
}

/*========================= Featured In ========================*/
.homepage .featured {
  margin-bottom: 100px;
}
.homepage .featured .image {
  width: 200px;
  margin: 0 auto;
}

.homepage .sponsor.featured {
  padding: 0;
}

/*============================ Blogs ==========================*/
.homepage .blogs {
  padding: 65px 0 110px;
  /* padding: 25px 0 100px; */
}

.homepage .blogs .description h5 {
  margin-bottom: 8px;
  max-width: 90%;
  line-height: 1.3;
  font-size: 30px;
}

.homepage .blogs .description p {
  font-size: 16px;
  color: #42464a;
  margin-bottom: 20px;
  max-width: 70%;
}

.homepage .blogs .description a {
  font-size: 14px;
  font-weight: 600;
  padding: 16px 26px 15px;
}

.homepage .blogs .card {
  margin-right: 15px;
}

.homepage .blogs .card .card-img-top {
  width: 100%;
  height: 200px;
  border-bottom: 1px solid #f0f0f5;
}

.homepage .blogs .card-body {
  padding: 20px 25px;
  border-bottom: 1px solid #f0f0f5;
  border-radius: 10px;
}

.homepage .blogs .card-body h5 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.5;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
  height: 45px;
}

.homepage .blogs .card-body p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 500;
}

.homepage .blogs .card-body p.author {
  font-size: 14px;
  margin-bottom: 0;
  color: #42464a;
}

.homepage .blogs .card-body p.author span {
  font-weight: 600;
}

/*========================= Loading Button =====================*/
.loading-button button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-button button .spinner-border {
  width: 16px;
  height: 16px;
  border-width: 2px;
  margin-right: 5px;
}

/*============================ Review =========================*/
.homepage .review {
  padding-bottom: 25px;
}

.homepage .review h5 {
  margin-bottom: 30px;
}

.homepage .review .single {
  display: flex;
}

.homepage .review .single .col-4 {
  padding-right: 0;
}

.homepage .review .single .col-8 {
  padding-left: 0;
}

.homepage .review .single .col-4 img {
  height: 384px;
}

.homepage .review .single .items .name {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}

.homepage .review .single .items .role {
  font-size: 20px;
  margin-bottom: 26px;
  font-weight: 400;
}

.homepage .review .single .items .name-logo {
  display: flex;
  justify-content: space-between;
}

.homepage .review .single .items .name-logo i {
  font-size: 47px;
  padding-top: 5px;
}

.homepage .review .single .details {
  padding: 0 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 384px;
}

.homepage .review .single .items p {
  font-size: 20px;
  font-weight: 300;
}

.homepage .review .single-item {
  /* padding: 20px; */
  color: #fff;
  border-radius: 5px;
  height: 410px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.homepage .review .single-item .for-image {
  width: 100px;
  border-radius: 50%;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.homepage .review .single-item .for-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.homepage .review .single-item .details {
  margin-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

.homepage .review .single-item .footer {
  padding-left: 25px;
  padding-right: 25px;
}

.homepage .review .single-item .footer h5 {
  margin-bottom: 0;
}

.homepage .review .mobile .react-multiple-carousel__arrow::before,
.homepage .review .mobile .react-multiple-carousel__arrow::before {
  color: #fff;
}

.homepage .review .mobile .react-multiple-carousel__arrow,
.homepage .review .mobile .react-multiple-carousel__arrow:hover {
  border-radius: 50% !important;
  background: #31b5c4;
}

.homepage .review .mobile .react-multiple-carousel__arrow--right,
.homepage .review .mobile .react-multiple-carousel__arrow--left {
  top: 50%;
  transform: translate(0, -50%);
}

.homepage .review .mobile .react-multiple-carousel__arrow--left {
  left: 0;
}

.homepage .review .mobile .react-multiple-carousel__arrow--right {
  right: 0;
}

/*=============================================================*/
/*========================= CategoryPage ======================*/
/*=============================================================*/
.cate-page .global.achievement {
  margin-top: 0;
  background: #fff;
}

.cate-page .global.req-order {
  margin-bottom: 0;
  background: #ebf3f5;
  margin-top: 0;
}

.cate-page .title {
  padding-top: 22px;
  padding-bottom: 24px;
}

.cate-page .title .name i {
  font-size: 18px;
}

.cate-page .title .lists {
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
}

.cate-page .title .lists span {
  padding: 9px 29px 8px;
  border: 1px solid #e1e1e8;
  border-radius: 18px;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 10px;
}

.cate-page .title .lists span.active {
  background: #f0f0f5;
}

.cate-page .all-labs {
  padding: 50px 0;
  background: #fbfbfb;
}

.cate-page .all-labs .lists .card {
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 8px #006a500a;
  margin-bottom: 17px;
}

.cate-page .all-labs .lists .card .body {
  padding: 20px 24px;
}

.cate-page .all-labs .lists .card .body h5 {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #e1e1e8;
  padding-bottom: 9px;
  margin-bottom: 12px;
}

.cate-page .all-labs .lists .card .body label {
  font-size: 13px;
  cursor: pointer;
}

.cate-page .all-labs .lists .card .body .form-check-input {
  margin-top: 0.45rem;
  cursor: pointer;
}

.cate-page .all-labs .lists .card .body .form-group {
  margin-bottom: 5px;
}

.cate-page .all-labs .diag-list {
  padding-left: 25px;
}




.dt-page .search input {
  padding: 28px 25px;
  border: none;
  outline: none;
  background: #f0f0f5;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 50px;
}

.dt-page .search form button {
  border: none;
  outline: none;
  padding: 0 25px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: #f0f0f5;

}

.cate-page .all-labs .diag-list .search {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  margin-bottom: 35px;
}

.dt-page .search button.reset {
  width: 155px;
}
.dt-page .description.search{
  margin-bottom: 50px;
}

.dt-page .col-md-2.s{
  padding: 0px;
  left: -5px;
}
.s-icon.btn-secondary{
    background: #C1E9ED 0% 0% no-repeat padding-box;
    color: #1D1D1D;
    padding: 16px;
    width: 100%;
    border: 0px !important;
}


.dt-page .description.search button.s-icon{
    position: absolute;
    left: 11px;
    padding: 16px 12px;
    padding-left: 25px;

}

.dt-page .input-group{
  position: relative;
  left: -28px;
}



.cate-page .all-labs .diag-list .search input {
  padding: 28px 25px;
  border: none;
  outline: none;
  background: #f0f0f5;
  font-size: 16px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.cate-page .all-labs .diag-list .search form button {
  border: none;
  outline: none;
  padding: 0 25px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: #f0f0f5;
}

.cate-page .all-labs .diag-list .search {
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  margin-bottom: 35px;
}

.cate-page .all-labs .diag-list .search button.reset {
  width: 155px;
}

.cate-page .all-labs .diag-list .checkups {
  padding-bottom: 0;
}

.cate-page .all-labs .diag-list .search select {
  border: none;
  outline: none;
  background: #f0f0f5;
  border-radius: 5px;
  padding-left: 22px;
  padding-right: 22px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 13px;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 155px;
  /* margin-right: 17px; */
  margin-left: 17px;
}

.cate-page .all-labs .diag-list .search form {
  width: 100%;
}

.cate-page .all-labs .diag-list .pagi {
  border-top: 1px solid #e1e1e8;
  border-bottom: 1px solid #e1e1e8;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cate-page .all-labs .diag-list .pagi .page-link {
  border: none;
  background: #fbfbfb;
  color: #3c4043;
  font-weight: 600;
  font-size: 14px;
}
.cate-page .all-labs .diag-list .pagi .page-item.active .page-link {
  background: #ebf3f5;
  color: #31b5c4;
  border-radius: 5px;
}

.cate-page .all-labs .diag-list .pagi .count {
  margin-bottom: 0;
  font-size: 14px;
}

/*=============================================================*/
/*========================= PackagePage =======================*/
/*=============================================================*/
.package-page .packages {
  padding: 100px 0;
  padding-bottom: 65px;
}

.partner-package .packages {
  padding-top: 55px;
}

.package-page .packages .descrip {
  margin-bottom: 30px;
}

.package-page .packages .item {
  margin-bottom: 35px;
}

.package-page .packages .btns {
  margin-top: 10px;
}

.package-page .packages .btns button {
  font-size: 15px;
  padding: 11px 25px;
  background: #f0f0f5;
}

.package-page .packages .btns button.prev {
  margin-right: 7.5px;
}

.package-page .packages .btns button.next {
  margin-left: 7.5px;
}

/*=============================================================*/
/*========================== Sponsor ==========================*/
/*=============================================================*/
.sponsor {
  padding: 75px 0;
}

.sponsor img {
  height: 50px;
  margin: 0 50px;
}

.support-by img {
  height: 80px;
  margin: 0 50px;
}
.support-by .images {
  display: flex;
  justify-content: center;
  align-items: center;
}
.support-by img.s-b {
  height: 100px;
  margin: 0 50px;
}
.support-by img.s-p {
  height: 60px;
  margin: 0 50px;
}

@media only screen and (max-width: 650px) {
  .desktop-d{
    display: none !important;
  }
  .mobile-d{
    display: flex;
  }
  .container.support-by{
    margin-bottom:50px;
  }
  .about-img{
    width:100%;
  }
}
@media only screen and (min-width: 650px) {
  .desktop-d{
    display: flex;
  }
  .mobile-d{
    display: none !important;
  }
  .container.support-by{
    margin-bottom:100px;
  }
}


.sponsor img.lalpaths {
  height: 80px;
}

.sponsor img.thyrocare {
  height: 140px;
}

.sponsor.featured img.daily-star {
  height: 40px;
}

.sponsor.featured img.dhaka-tribun {
  height: 25px;
}

.sponsor.featured img.new-adge {
  height: 30px;
}

/*=============================================================*/
/*========================== Patients =========================*/
/*=============================================================*/
.all_patient {
  padding: 85px 0;
}

.all_patient .title.main-heading {
  margin-bottom: 40px;
}

.all_patient .navs .single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 2px solid #cacad3;
  border-radius: 5px;
  padding: 10px 15px;
}

.all_patient .navs .single.active {
  background: #c9f7f5;
  background: #eee;
}

.all_patient .navs .single:not(:last-child) {
  margin-bottom: 15px;
}

.all_patient .navs .single p {
  margin-bottom: 0;
}

.all_patient .navs .single img {
  width: 7px;
}

.all_patient .patients .single .name-sex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all_patient .patients .single .name-sex h5 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
}

.all_patient .patients .single .name-sex i {
  color: #dc3545;
  cursor: pointer;
  font-size: 20px;
}

.all_patient .patients .single p {
  margin-bottom: 0;
  font-size: 15px;
}

.all_patient .patients .single {
  padding: 15px 15px;
  border: 2px solid #cacad3;
  border-radius: 5px;
  margin-bottom: 30px;
}

.all_patient .patients .single:not(:last-child) {
  margin-bottom: 15px;
}

.all_patient .add-another button {
  font-size: 14px;
  border: 2px solid #cacad3;
  padding: 14px 26px;
  background: #fff;
  margin-top: 15px;
}

.all_patient form {
  margin-top: 8px;
}

.all_patient form .name-gender,
.all_patient form .age-rx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* .all_patient form .name, */
/* .all_patient form .gender { */
/* flex-grow: 1; */
/* width: 48%; */
/* margin-right: 20px; */
/* } */

.all_patient form .name .nainp,
.all_patient form .gender .nainp {
  /* margin-right: 20px; */
  padding: 15px 22px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #f0f0f5;
  width: 400px;
  /* flex-grow: 1; */
  outline: none;
}

.all_patient form .name h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.all_patient form .name .gender .male-female {
  display: flex;
}

.all_patient form .name .gender .male-female .gender {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 18px;
  margin-bottom: 0;
  padding-left: 28px;
  color: #7c8399;
}
.all_patient form .name .gender .nainp {
  background-color: #fff;
}

.all_patient form .name .gender .nainp::after {
  position: absolute;
  content: "";
  top: 14px;
  right: 14px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181c32;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid #e4e6ef;
  border-radius: 5px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  outline: none;
}

.form-select:focus,
.form-select:focus-visible {
  border: 1px solid #e4e6ef !important;
}

.all_patient form .name .gender .male-female .gender input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.all_patient form .name .gender .male-female .gender .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #707070;
}

.all_patient form .name .gender .male-female .gender input:checked ~ .checkmark {
  background-color: #707070;
}

.all_patient form .age-rx .react-datepicker-wrapper .react-datepicker__input-container input {
  padding: 15px 22px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #f0f0f5;
  outline: none;
  width: 400px;
}

.all_patient form button {
  font-size: 14px;
  border-radius: 5px;
  border: 0 solid #cacad3;
  padding: 13px 23px;
  margin-bottom: 18px;
  /* background: #31b5c4; */
  color: #fff;
}

.patients-box .single:hover {
  border-color: #31b5c4 !important;
}

/*=============================================================*/
/*========================== Footer ===========================*/
/*=============================================================*/
footer .details {
  background-color: #ebf3f5;
  /* background-color: #263d49; */
  padding: 75px 0;
  color: #fff;
}

footer .details .footer-logo {
  margin-bottom: 18px;
}

footer .details .footer-logo img {
  width: 71px;
}

footer .details .footer-logo p.logo-name {
  letter-spacing: 2.8px;
  margin: 0;
  color: #3c4043;
  font-size: 12px;
}

footer .details .footer-logo img.mobile {
  display: none;
}

footer .details .company-details h5 {
  font-size: 15px;
  font-weight: 600;
  color: #3c4043;
}

footer .details .company-details p.email {
  margin-top: 20px;
}

footer .details .company-details p {
  font-size: 15px;
  margin-bottom: 3px;
  color: #3c4043;
  /* color: #787887; */
}

footer .details ul.social-icons,
.header ul.social-icons {
  display: flex;
  margin-top: 30px;
}

footer .details ul.social-icons li,
.header ul.social-icons li {
  margin-right: 18px;
}

footer .details ul.social-icons li a {
  color: #3c4043;
  /* color: #fff; */
  font-size: 18px;
}

.header ul.social-icons li a {
  color: #31b5c4;
  font-size: 18px;
}

footer .details ul.social-icons li a i,
.header ul.social-icons li a i {
  transition: all 0.2s;
}

footer .details ul.social-icons li a .facebook:hover {
  color: #2d88ff;
}

footer .details ul.social-icons li a .linkedin:hover {
  color: #0a66c2;
}

footer .details ul.social-icons li a .twitter:hover {
  color: #1da1f2;
}

footer .details ul.social-icons li a .instagram:hover {
  color: #c13584;
}

footer .details .useful-links a {
  font-size: 15px;
  color: #3c4043;
}

footer .details .useful-links h4 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #3c4043;
}

footer .details .useful-links ul li {
  margin-bottom: 6px;
}

footer .links {
  padding: 20px 0;
  background-color: #e1ecef;
  /* background-color: #f0f0fa; */
}

footer .links .parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .links .parent .left ul {
  display: flex;
}

footer .links .parent .left ul li {
  margin-right: 25px;
}

footer .links .parent .left ul li a {
  font-size: 15px;
  font-weight: 400;
}

footer .links .parent .right {
  font-size: 15px;
}

footer .links .parent .right a {
  font-weight: 600;
}

/*========================= Modal ==========================*/
@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 607px;
  }
}

.modal .modal-body {
  padding: 0;
}

.modal .diagnostic-select .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f0f0f5;
  padding: 25px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.modal .diagnostic-select .head h5 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 0;
}

.modal .diagnostic-select .head p {
  color: #787887;
  font-size: 12px;
  margin-top: 5px;
}

.modal .diagnostic-select .head button {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 5px;
  background: #f0f0f5;
  border-color: #cacad3;
  color: #787887;
}

.modal .diagnostic-select .bodies {
  padding-top: 23px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}

.modal .diagnostic-select .bodies .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}

.modal .diagnostic-select .bodies .details .left h5 {
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}

.modal .diagnostic-select .bodies .details .left p {
  color: #787887;
  font-size: 12px;
  margin-right: 15px;
  max-width: 80%;
}

.modal .diagnostic-select .bodies .details .right select {
  outline: none;
  border: 1px solid #cacad3;
  border-radius: 5px;
  padding: 10px 15px;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;
  width: 110px;
}

.modal .diagnostic-select .bodies .lists {
  max-height: 255px;
  overflow-y: scroll;
}

.modal .diagnostic-select .bodies .lists::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.modal .diagnostic-select .bodies .lists::-webkit-scrollbar-track {
  background: #f0f0f5;
}
.modal .diagnostic-select .bodies .lists::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}
/* .modal .diagnostic-select .bodies .lists */

.modal .diagnostic-select .bodies .lists p.not-found {
  font-size: 15px;
  margin-bottom: 22px;
  padding-left: 3px;
}

.modal .diagnostic-select .bodies .lists .single .radio .all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .diagnostic-select .bodies .lists .single .wever span {
  padding: 5px 12px;
  background-color: #2f932f;
  font-size: 14px;
  font-weight: 600;
  min-width: 70px;
}

.modal .diagnostic-select .bodies .lists .single {
  padding: 12px 0;
  border-top: 1px solid #f0f0f5;
}

.modal .diagnostic-select .bodies .lists .single .radio {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

.modal .diagnostic-select .bodies .lists .single .radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal .diagnostic-select .bodies .lists .single .radio .checkmark {
  position: absolute;
  top: 30%;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 3px solid #31b5c4;
}

.modal .diagnostic-select .bodies .lists .single .radio input:checked ~ .checkmark {
  background-color: #31b5c4;
}

/* .modal .diagnostic-select .bodies .lists .single .radio .button {
  width: 25px;
  height: 25px;
  border: 3px solid #31b5c4;
  border-radius: 50%;
  cursor: pointer;
}

.modal .diagnostic-select .bodies .lists .single .radio .button.active {
  background: #31b5c4;
} */

.modal .diagnostic-select .bodies .lists .single .hospital {
  display: flex;
  align-items: center;
}

.modal .diagnostic-select .bodies .lists .single .hospital .logo img {
  border: 1px solid #f0f0f5;
  padding: 9px 13px;
  width: 70px;
}

.modal .diagnostic-select .bodies .lists .single .hospital .name {
  margin-left: 15px;
}

.modal .diagnostic-select .bodies .lists .single .price {
  display: flex;
  align-items: center;
}

.modal .diagnostic-select .bodies .lists .single .hospital .name h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.modal .diagnostic-select .bodies .lists .single .hospital .name p {
  font-size: 14px;
  margin-bottom: 0;
}

.modal .diagnostic-select .bodies .lists .single .price .old {
  font-size: 15px;
  text-decoration: line-through;
  margin-left: 15px;
  min-width: 70px;
}

.modal .diagnostic-select .bodies .lists .single .price .new {
  font-size: 15px;
  font-weight: 700;
  margin-left: 15px;
  margin-right: 10px;
  min-width: 70px;
}

.modal .diagnostic-select .foot {
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 40px;
}

.modal .diagnostic-select .foot button,
.modal .diagnostic-select button.next-step {
  border-radius: 5px;
  font-weight: 600;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 15px;
}

/* Person Modal */
.modal .diagnostic-select.patient .head .descrip {
  display: flex;
  align-items: center;
}

.modal .diagnostic-select.patient .head .descrip img {
  height: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.modal .diagnostic-select.patient .head h5 {
  margin-bottom: 5px;
}

.modal .diagnostic-select.patient .head p {
  color: #787887;
  font-size: 12px;
}

.modal .diagnostic-select.patient .bodies .details .left h5 {
  font-size: 20px;
}

.modal .diagnostic-select.patient .bodies .details .right {
  display: flex;
  align-items: center;
}

.modal .diagnostic-select.patient .bodies .details .right .person {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid #cacad3;
  border-radius: 5px;
  padding: 9px 18px;
  margin-bottom: 0;
  padding-left: 45px;
}

.modal .diagnostic-select.patient .bodies .details .right .person.first {
  margin-right: 20px;
}

.modal .diagnostic-select.patient .bodies .details .right .person input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal .diagnostic-select.patient .bodies .details .right .person .checkmark {
  position: absolute;
  top: 9px;
  left: 15px;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  border: 3px solid #31b5c4;
}

.modal .diagnostic-select.patient .bodies .details .right .person input:checked ~ .checkmark {
  background-color: #31b5c4;
}

.modal .diagnostic-select.patient .bodies {
  padding-top: 30px;
  padding-bottom: 0;
}

.modal .diagnostic-select.patient .bodies .details {
  margin-bottom: 30px;
}

.modal .diagnostic-select.patient .bodies .name {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.modal .diagnostic-select.patient .bodies .name h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  margin-bottom: 8px;
}

.modal .diagnostic-select.patient .bodies .name .nainp {
  /* margin-right: 20px; */
  padding: 0.75rem 3rem 0.75rem 1rem;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #f0f0f5;
  width: 100%;
  outline: none;
}

.modal .diagnostic-select.patient .bodies .age-rx input {
  padding: 0.75rem 3rem 0.75rem 1rem !important;
  width: auto !important;
}

.modal .diagnostic-select.patient .bodies .name .gender .male-female {
  display: flex;
}

.modal .diagnostic-select.patient .bodies .name .gender .male-female .gender {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  -webkit-user-select: none;
  user-select: none;
  padding: 0 18px;
  margin-bottom: 0;
  padding-left: 28px;
  color: #7c8399;
}

.modal .diagnostic-select.patient .bodies .name .gender .male-female .gender input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal .diagnostic-select.patient .bodies .name .gender .male-female .gender .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 3px solid #707070;
}

.modal .diagnostic-select.patient .bodies .name .gender .male-female .gender input:checked ~ .checkmark {
  background-color: #707070;
}

.modal .diagnostic-select.patient .bodies .age-rx {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.modal .diagnostic-select.patient .bodies form button {
  font-size: 14px;
  border-radius: 5px;
  border: 0 solid #cacad3;
  padding: 13px 23px;
  margin-bottom: 18px;
  background: #31b5c4;
  color: #fff;
}

.modal .diagnostic-select.patient .bodies .age-rx .react-datepicker-wrapper .react-datepicker__input-container input {
  padding: 21px 22px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #f0f0f5;
  outline: none;
  width: 328px;
}

.modal .diagnostic-select.patient .bodies .age-rx .left {
  border: 2px solid #f0f0f5;
  border-radius: 5px;
  padding: 21px 22px;
  margin-right: 20px;
}

.modal .diagnostic-select.patient .bodies .age-rx .left input {
  border: none;
  outline: none;
  font-size: 18px;
  border-right: 1px solid #c8c8c8;
  width: 185px;
}

.modal .diagnostic-select.patient .bodies .age-rx .left select {
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  margin-left: 13px;
  background: transparent;
}

.modal .diagnostic-select.patient .bodies .age-rx .right input {
  width: 140px;
}

.modal .diagnostic-select.patient .bodies .age-rx .right input::-webkit-file-upload-button {
  visibility: hidden;
}

.modal .diagnostic-select.patient .bodies .age-rx .right input::before {
  cursor: pointer;
  content: "Upload Rx.";
  display: inline-block;
  background: #f0f0f5;
  border-radius: 5px;
  padding: 14px 33px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 600;
  font-size: 14px;
}

.modal .diagnostic-select.patient .bodies .age-rx .right .design {
  font-size: 14px;
  font-weight: 600;
  background-color: #f0f0f5;
  border-radius: 5px;
  display: inline-block;
  padding: 14px 33px;
  cursor: pointer;
}

.modal .diagnostic-select.patient .foot .add-another button {
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid #cacad3;
  padding: 14px 26px;
  margin-bottom: 18px;
  background: #fff;
}

/* Family modal */
.modal .diagnostic-select.family .bodies .add-another button {
  font-size: 14px;
  border-radius: 5px;
  border: 2px solid #cacad3;
  padding: 14px 26px;
  margin-bottom: 18px;
  background: #fff;
}

.modal .diagnostic-select.family .bodies .members {
  margin-bottom: 30px;
}

.modal .diagnostic-select.family .bodies .members .individual {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal .diagnostic-select.family .bodies .members .individual input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.modal .diagnostic-select.family .bodies .members .individual .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #eee;
  border-radius: 50%;
}

.modal .diagnostic-select.family .bodies .members .individual input:checked ~ .checkmark {
  background-color: #31b5c4;
}

/*=============================================================*/
/*========================= Showcase ==========================*/
/*=============================================================*/
.mobile-semi-nav.mobile-semi-nav-src-pg .search {
  padding: 15px 0 18px;
  box-shadow: 0 3px 7px -4px rgb(0 0 0 / 16%);
}

.mobile-semi-nav.mobile-semi-nav-src-pg .search.s-off {
  box-shadow: none;
}

.mobile-semi-nav.mobile-semi-nav-src-pg p {
  font-size: 14px;
  padding-top: 16px;
  padding-bottom: 2px;
}

.mobile-semi-nav.mobile-semi-nav-src-pg p img {
  width: 13px;
  margin-right: 15px;
  cursor: pointer;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .default-list {
  padding: 25px 0;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .default-list h5.main-heading {
  margin-bottom: 20px !important;
}
.mobile-semi-nav.mobile-semi-nav-src-pg .checkups .items .single-item .card .card-body p {
  padding: 0;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page {
  padding: 0 0 18px;
  box-shadow: 0 3px 7px -4px rgb(0 0 0 / 16%);
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head {
  display: flex;
  box-shadow: 0 3px 7px -4px rgb(0 0 0 / 16%);
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head div {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head div.left {
  padding-bottom: 18px;
  padding-left: 15px;
  padding-right: 25px;
  /* margin-right: 5px; */
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head div.right {
  padding-left: 25px;
  padding-right: 25px;
  /* margin-left: 5px; */
  padding-bottom: 18px;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head div.right.active {
  color: #31b5c4;
  border-bottom: 2px solid #31b5c4;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .head div.left.active {
  border-bottom: 2px solid #31b5c4;
  color: #31b5c4;
}
.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .results {
  margin-top: 24px;
  padding: 0;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .checkups {
  padding-bottom: 0px;
}

.mobile-semi-nav.mobile-semi-nav-src-pg .srch-page .checkups .items .view-more button {
  margin-bottom: 30px;
}
/* .mobile-semi-nav.mobile-semi-nav-src-pg  */

/*=============================================================*/
/*=========================== 404 =============================*/
/*=============================================================*/
section.not-found {
  padding: 75px 0;
}

section.not-found h1 {
  font-size: 45px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
}

section.not-found a {
  padding: 16px 26px;
  font-size: 18px;
  font-weight: 600;
}

section.not-found img.main {
  width: 50%;
  display: flex;
  margin: 0 auto;
}

section.not-found img.icon {
  width: 23px;
  transform: rotate(180deg);
  margin-right: 5px;
}

/*=============================================================*/
/*======================== Cart Tests =========================*/
/*=============================================================*/
.cart-p-mbl .single .patient {
  color: #fff;
  font-size: 14px;
  background-color: #2a9ba8;
  padding: 14px 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.cart-p-mbl .single .tests {
  margin: 0 15px;
}

.cart-p-mbl .single .tests ul li {
  border: 1px solid #f0fafb;
  border-radius: 4px;
  font-size: 12px;
  padding: 12px 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.cart-p-mbl .single .tests ul li:last-child {
  margin-bottom: 24px;
}

.cart-p-mbl .single .tests ul li .las {
  display: flex;
  align-items: center;
}

.cart-p-mbl .single .tests ul li i {
  color: #b5b5c3;
  font-size: 18px;
  cursor: pointer;
  margin-left: 68px;
}

.cart-p-mbl .single .tests a.add-an-btn {
  font-size: 14px;
  padding: 9px 13px 8px 14px;
  border: 1px solid #e1f5f7;
  border-radius: 5px;
}

.cart-p-mbl .another-pat {
  margin: 30px 15px 80px;
  background: #f0fafb;
  border-radius: 5px;
  padding: 14px 26px;
}

.cart-p-mbl .another-pat a {
  color: #787887;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-p-mbl .another-pat a i {
  font-size: 16px;
}

.cart-p-mbl .have-code {
  font-size: 14px;
  color: #1c1c1c;
  font-weight: 600;
  margin-bottom: 20px;
}

.cart-p-mbl .have-code i {
  font-size: 16px;
  margin-right: 15px;
}

.cart-p-mbl .have-code .input-code {
  margin: 16px 15px 0;
  display: flex;
}

.cart-p-mbl .have-code .input-code input {
  border: none;
  outline: none;
  background-color: #f0fafb;
  border-radius: 5px;
  padding: 14px 16px;
  flex: 1;
  margin-right: 10px;
  font-size: 14px;
}

.cart-p-mbl .have-code .input-code input::placeholder {
  color: #acacac;
}

.cart-p-mbl .have-code .input-code button {
  border: none;
  outline: none;
  background-color: #31b5c4;
  border-radius: 5px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  color: #fff;
}

.cart-p-mbl .checkout-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  background-color: #e1f5f7;
}

.cart-p-mbl .checkout-btn .left .tp {
  font-size: 14px;
  margin-bottom: 4px;
}

.cart-p-mbl .checkout-btn .left .bd {
  display: flex;
  align-items: end;
}

.cart-p-mbl .checkout-btn .left .bd .tk {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}

.cart-p-mbl .checkout-btn .left .bd .vd {
  font-size: 12px;
  margin-bottom: 0;
  margin-left: 8px;
}

.cart-p-mbl .checkout-btn .right a {
  padding: 16px 37px 16px;
  font: normal normal 600 14px/13px Open Sans;
  letter-spacing: 0px;
}

.cart-p-mbl .empty {
  margin: 80px 0;
}

.cart-p-mbl .empty .cart-icon {
  display: block;
  margin: 0 auto;
  height: 60px;
  margin-bottom: 16px;
}

.cart-p-mbl .empty p.ce {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 4px;
}

.cart-p-mbl .empty p.dt {
  font-size: 12px;
  text-align: center;
  width: 60%;
  margin: 0 auto 36px;
}

.cart-p-mbl .empty a {
  padding: 14px 45px;
  font-size: 14px;
  font-weight: 600;
}

.cart-payment-modal {
  padding: 22px 32px 26px;
}

.cart-payment-modal .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cart-payment-modal .header p {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
}

.cart-payment-modal .header img {
  width: 15px;
}

.cart-payment-modal ul li {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-payment-modal ul li:not(:last-child) {
  margin-bottom: 7px;
}

.cart-payment-modal ul.mai-p {
  margin-bottom: 13px;
}

/*=============================================================*/
/*===================== Diagnostic Tests ======================*/
/*=============================================================*/
.diag-test-page .all-items {
  padding: 80px 0 100px;
}

.diag-test-page .all-items .description {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.diag-test-page .all-items .description form {
  width: 467px;
}

.diag-test-page .all-items .description form input {
  padding: 30px 25px;
  font-size: 16px;
  border: none;
  background: #f0f0f5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.diag-test-page .all-items .description form button {
  border: none;
  padding: 0 25px;
  border-radius: 5px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: #f0f0f5;
}

.diag-test-page .all-items .items .card {
  margin-bottom: 30px;
}

.diag-test-page .all-items .items .card .card-body {
  padding: 15px 20px;
}

.diag-test-page .all-items .items .card .card-body h5 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}
.diag-test-page .all-items .items .card .card-body p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 20px;
  min-height: 71px;
}
.diag-test-page .all-items .items .card .card-body button {
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;
  background: #f0f0f5;
  color: #787887;
}

.diag-test-page .all-items .pagination {
  margin-top: 40px;
}

.diag-test-page .all-items .pagination button {
  font-size: 15px;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 115px;
}

.diag-test-page .all-items .pagination button.prev {
  margin-right: 7.5px;
}

.diag-test-page .all-items .pagination button.next {
  margin-left: 7.5px;
}
/*=============================================================*/
/*===================== Privacy Policy ========================*/
/*=============================================================*/
.privacy-policy {
  padding: 80px 0;
}

.privacy-policy p {
  text-align: justify;
}

.privacy-policy p:not(:last-child) {
  margin-bottom: 40px;
}

.privacy-policy ul {
  list-style: circle;
  padding-left: 3rem;
}

.privacy-policy ul li,
.privacy-policy ol li {
  margin-bottom: 5px;
}

.privacy-policy ol {
  padding-left: 3rem;
}

.company-doc .title {
  font-weight: 600;
  width: 80%;
  margin: auto;
  margin-bottom: 10px;
}

.company-doc img {
  width: 80%;
  display: block;
  margin: auto;
}

.privacy-policy.company-doc p {
  width: 80%;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.company-doc p span {
  font-weight: 600;
}

.company-doc .img-item {
  margin-top: 60px;
}

/*=============================================================*/
/*===================== Login / Sign Up =======================*/
/*=============================================================*/
.losign {
  padding: 75px 0;
}

.losign .socials button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  padding: 15px 0;
  width: 291px;
  margin: auto;
}

.losign .socials button img {
  width: 18px;
  margin-right: 13px;
}

.losign .socials button p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}

.losign .socials button.google {
  background: #fc5a5a;
  margin-bottom: 10px;
}

.losign .socials button.facebook {
  background: #0062ff;
  margin-bottom: 30px;
}

.losign form .form-group {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.losign form input,
.losign form select.sex {
  display: block;
  background: #f0f0f5;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 291px;
  margin: auto;
  padding: 15px 20px;
  margin-bottom: 10px;
  color: #000;
}

.losign form select.sex {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 9px;
}

.losign form select.sex::-ms-expand {
  display: none;
}

.losign form .form-group .show-hide {
  position: relative;
}

.losign form .form-group .show-hide img {
  position: absolute;
  top: 14px;
  right: 6%;
  cursor: pointer;
}

.losign form button {
  margin-top: 10px;
  border: none;
  outline: none;
  background-image: linear-gradient(to right, #6e63e7, #31b5c4);
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 0;
  width: 291px;
}

.losign form button.disabled {
  opacity: 0.5;
}

.login .title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
  text-align: center;
}

.login .bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.login .bottom a {
  font-weight: 700;
}

.signup .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: center;
}

.signup input.opt {
  background: #f1f1f5;
  opacity: 0.4;
}

.signup .second form button {
  background: #31b5c4;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.signup .second form .loading-button {
  width: 100%;
}

.signup .bottom {
  text-align: center;
  font-size: 14px;
  width: 291px;
  margin: 0 auto;
  text-align: center;
}

.signup .bottom span {
  font-weight: 600;
}

.signup form .form-group.for-otp {
  background: #f0f0f5;
  width: 291px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 5px;
  align-items: center;
  padding: 0 10px 0px 0px;
}

.signup form .form-group.for-otp input {
  width: 200px;
  margin: auto;
}

.signup form .form-group.for-otp input.loading {
  width: 188px;
}

.signup form .form-group.for-otp button {
  font-size: 12px;
  font-weight: 600;
  padding: 6px 12px !important;
  width: initial;
  background: #31b5c4;
  margin-top: 0;
}

.signup form .form-group.for-otp button .spinner-border {
  width: 10px;
  height: 10px;
}

.signup .second form .form-group.for-otp button {
  margin: auto;
}

.signup .second form .form-group.for-otp button.disabled {
  opacity: 0.5;
}

/*=============================================================*/
/*========================= Payment ===========================*/
/*=============================================================*/
.payment-status {
  padding: 33px 0;
}

.payment-status .header .img-section {
  position: relative;
  width: 51px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.payment-status .header .img-section .circle {
  width: 51px;
}

.payment-status .header .img-section .sign {
  width: 38px;
  position: absolute;
  top: 6px;
  right: 0;
}

.payment-status .header h5 {
  font-size: 22px;
  font-weight: 600;
}

.payment-status .card {
  padding: 35px;
  width: 362px;
  margin: 0 auto;
  margin-top: 35px;
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 6px #73658645;
}

.payment-status .card .terms {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  color: #787887;
}

.payment-status .card h5.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

.payment-status .card .cost .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.payment-status .card .cost .price p {
  font-size: 18px;
  margin-bottom: 0;
  color: #1d1d1d;
}

.payment-status .card .cost .price p.bold-text {
  font-weight: 600;
}

.payment-status .card .cost .break {
  width: 100%;
  height: 2px;
  background: #2d2d32;
  margin-bottom: 12px;
}

.payment-status .card .terms a {
  font-weight: 600;
  color: #787887;
}

.payment-status .card a {
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 7px !important;
}

/*=============================================================*/
/*=========================== Order ===========================*/
/*=============================================================*/

/*====================== Order History ========================*/
.order-history {
  padding-top: 85px;
  padding-bottom: 135px;
}

.order-history h5.main-heading {
  margin-bottom: 40px;
}

.order-history .single {
  background: #f0f0f5;
  padding: 38px 45px 45px 45px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.order-history .single .header,
.order-history .single .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-history .single .header {
  margin-bottom: 15px;
}

.order-history .single .header .left .id {
  font-size: 18px;
  font-weight: 600;
  color: #1d1d1d;
  margin-bottom: 5px;
}

.order-history .single .header .left .date {
  font-size: 16px;
  color: #1d1d1d;
  margin-bottom: 0;
}

.order-history .single .header .right p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.order-history .single .header .right p span {
  margin-left: 15px;
}

.order-history .single .footer .left {
  margin-bottom: 0;
  font-size: 16px;
}

.order-history .single .footer .left span {
  font-weight: 600;
  text-transform: capitalize;
}

.order-history .single .footer .left span.confirmed,
.order-history .single .footer .left span.completed {
  color: #2f932f;
}

.order-history .single .footer .left span.pending,
.order-history .single .footer .left span.service_on_hold,
.order-history .single .footer .left span.waiting_for_confirmation {
  color: #ffc107;
}

.order-history .single .footer .left span.cancel {
  color: #dc3545;
}

.order-history .single .footer .right a {
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 144px;
  border-radius: 7px !important;
}

.order-history .single.unpaid .footer .right a.pay {
  margin-left: 15px;
  background: #2f932f;
}

.order-history .single .footer .right a.dnld {
  margin-right: 15px;
  width: 210px;
  border: 1.5px solid #31b5c4;
}

.order-history .single .footer .right a.dnld:hover {
  background: #f4f4f4;
}

.order-history .single .footer .right a.dnld img {
  width: 23px;
  margin-right: 10px;
}

.order-history .pagi button {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 11px 25px;
  background: #f0f0f5;
  margin-top: 30px;
}

/*======================= Order Placed ========================*/
.order-place-details {
  padding-top: 85px;
  padding-bottom: 200px;
}

.order-place-details .left h5.main-heading {
  margin-bottom: 10px;
  font-weight: 700;
}

.order-place-details .left p.id {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 46px;
}

.order-place-details .left p.date {
  font-size: 20px;
  margin-bottom: 50px;
}

.order-place-details .left p.date span {
  font-weight: 600;
}

.order-place-details .left p.date .report_del span.confirmed,
.order-place-details .left p.date .report_del span.completed {
  color: #00b341;
}

.order-place-details .left p.date .report_del span.pending,
.order-place-details .left p.date .report_del span.service_on_hold,
.order-place-details .left p.date .report_del span.waiting_for_confirmation {
  color: #ffc107;
}

.order-place-details .left p.date .report_del span.cancel {
  color: #dc3545;
}

.order-place-details .left p.date.report-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-place-details .left p.date.report-first button {
  padding: 14px 18px;
}

.order-place-details .left p.date.report-first button img {
  width: 23px;
  margin-right: 10px;
}

.order-place-details .left .location {
  /* display: flex; */
  margin-bottom: 22px;
}

.order-place-details .left .location p.left {
  font-size: 20px;
  margin-bottom: 0;
  margin-right: 5px;
}

.order-place-details .left p.mobile {
  margin-bottom: 22px;
}

.order-place-details .left p.sample_coll {
  margin-bottom: 22px;
}

.order-place-details .left p.email {
  margin-bottom: 22px;
}

.order-place-details .left .cart-summery {
  border-radius: 6px;
  background: #f0f0f5;
  padding: 17px 26px;
  margin-bottom: 28px;
  margin-top: 54px;
}

.cart-summery.check-page {
  border-radius: 6px;
  background: #f0f0f5;
  padding: 17px 26px;
  margin-bottom: 28px;
}

.order-place-details .left .cart-summery h5 {
  font-size: 22px;
}

.order-place-details .left .bottom {
  padding: 30px;
  border-radius: 6px;
  margin-bottom: 24px;
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-place-details .left .bottom h5 {
  color: #fff;
  font-size: 18px;
  margin-bottom: 0;
  width: 382px;
  line-height: 1.6;
}

.order-place-details .left .bottom button {
  color: #fff;
  background: #fbb018;
  padding: 20px 60px;
}

.order-place-details .left .terms,
.order-place-details .left .terms a {
  font-size: 14px;
  color: #736586;
  margin-bottom: 0;
}

.order-place-details .left .location .right p.bottom {
  font-size: 18px;
  margin-bottom: 0;
  /* color: #787887; */
  margin-top: 8px;
  width: 450px;
  padding: 0;
}

.order-place-details .left .location .right p.date {
  font-size: 17px;
  margin-bottom: 5px;
  color: #787887;
}

.order-place-details .right .card {
  padding: 35px;
  border: none;
  border-radius: 0;
  box-shadow: 0 3px 6px #73658645;
  position: sticky;
  top: 110px;
}

.order-place-details .right .card h5.title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

.order-place-details .right .card .cost .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.order-place-details .right .card .cost .price p {
  font-size: 18px;
  margin-bottom: 0;
  color: #1d1d1d;
}

.order-place-details .right .card .cost .price p.bold-text {
  font-weight: 600;
}

.order-place-details .right .card .cost .break {
  width: 100%;
  height: 2px;
  background: #2d2d32;
  margin-bottom: 12px;
}

.order-place-details .right .card .bottom button,
.order-place-details .right .card .bottom a {
  font-weight: 600;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 7px !important;
  margin-top: 15px;
}

.summery_cart .order_summery {
  display: flex;
  align-items: flex-start;
}

.summery_cart .order_summery .lab-logo span {
  font: normal normal 600 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #787887;
  margin-bottom: 8px;
}

.summery_cart .order_summery .lab-logo img {
  height: 62px;
  border: 1px solid #F0F0F5;
  border-radius: 5px;
  padding: 5px 11px 6px 11px;
}

.summery_cart .order_summery .lab-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 26px;
  margin-right: 50px;
}

.summery_cart .order_summery .lists {
  flex: 1;
}

.summery_cart .single {
  padding-bottom: 46px;
  /* margin-top: 22px; */
}

.summery_cart .single .patient {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FBFBFB;
  padding: 14px 26px;
  border-radius: 5px;
  margin-bottom: 16px;
  font-size: 14px;
}

.summery_cart .single .tests ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 26px;
  border: 1px solid #F0FAFB;
  border-radius: 4px;
  font-size: 12px;
}

.summery_cart .single .tests ul li:not(:last-child) {
  margin-bottom: 10px;
}

/* .summery_cart .single:not(:last-child) {
  border-bottom: 1px solid #f0f0f5;
} */

.summery_cart .single .person {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.summery_cart .single .person .name {
  font-size: 14px;
  color: #2d2d32;
}

.summery_cart .single .person .name span {
  font-weight: 700;
}

.summery_cart .single .person .age {
  font-size: 12px;
  margin-bottom: 0;
}

.summery_cart .single .details h5 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.summery_cart .single .details p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 13px;
}

.summery_cart .single .details p.price {
  color: #2d2d32;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 5px;
}

.summery_cart .single .branch {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 700;
}

.summery_cart .single .hospital p {
  color: #787887;
  font-size: 12px;
  margin-bottom: 8px;
}

.summery_cart .single .hospital img {
  width: 65px;
  padding: 9px 15px 10px 12px;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  /* margin-bottom: 30px; */
}

.order-place-details.details .right .card .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}

.order-place-details.details .right .card .bottom button,
.order-place-details.details .right .card .bottom a {
  background: #f0f0f5;
  border-radius: 7px !important;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  padding: 14px 21px;
}

.order-place-details.details .right .card .bottom button.primary {
  background: #31b5c4;
}

.order-place-details.details .right .card .bottom button img,
.order-place-details.details .right .card .bottom a img {
  width: 23px;
  margin-right: 10px;
}

.order-place-details.details .right .card .bottom p {
  color: #6e63e7;
}

.order-place-details .available-reports {
  margin-top: 60px;
}

.order-place-details .available-reports .cart-summery {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-place-details .available-reports .cart-summery button {
  font-size: 12px;
  padding: 10px 15px;
}

.order-place-details .available-reports .cart-summery button img {
  width: 17px;
  margin-right: 10px;
}

.order-place-details .available-reports .items .single:not(:last-child) {
  margin-bottom: 15px;
}

.order-place-details .available-reports .items .single .card {
  border-radius: 6px;
  border: none;
  background: #fbfbfb;
  padding: 26px 25px;
}

.order-place-details .available-reports .items .single .card .name {
  font-size: 14px;
  margin-bottom: 23px;
}

.order-place-details .available-reports .items .single .card .name span.age {
  font-size: 12px;
}

.order-place-details .available-reports .items .single .card .pdf .right a {
  font-size: 12px;
  padding: 10px 15px;
  font-weight: 600;
  border-radius: 5px;
}

.order-place-details .available-reports .items .single .card .pdf .right a img {
  width: 17px;
  margin-right: 10px;
}

.order-place-details .available-reports .items .single .card .pdf {
  display: flex;
  justify-content: space-between;
}

.order-place-details .available-reports .items .single .card .pdf:not(:last-child) {
  margin-bottom: 20px;
}

.order-place-details .available-reports .items .single .card .pdf .left {
  display: flex;
  align-items: center;
  color: #787887;
}

.order-place-details .available-reports .items .single .card .pdf .left img {
  width: 25px;
  margin-right: 13px;
}

.order-place-details .available-reports .items .single .card .pdf .left .name {
  font-size: 12px;
  margin-bottom: 2px;
  font-weight: 600;
}

.order-place-details .available-reports .items .single .card .pdf .left .date {
  font-size: 10px;
  margin-bottom: 0;
}

/*======================= Order Details =======================*/

/*=============================================================*/
/*=========================== FAQs ============================*/
/*=============================================================*/
.faqs {
  padding: 85px 0;
}

.faqs .title.main-heading {
  margin-bottom: 40px;
}

.faqs .details .card-header {
  background-color: rgba(0, 0, 0, 0.015);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.225); */
}

.faqs .details .card-header button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  background: none;
}

.faqs .details .card-header button:focus {
  outline: none;
}

.faqs .details .card-header button p {
  margin: 0;
}

.faqs .details .card-header button img {
  width: 9px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.faqs .details .card-header button img.active {
  transform: rotate(270deg);
}

.faqs .details .card-body p {
  font-size: 15px;
  margin: 0;
}

.faqs .details .card-body ul li {
  font-size: 15px;
}

/*=============================================================*/
/*========================== Press ============================*/
/*=============================================================*/
/* .press-release {
} */

.press-release section.head {
  background-color: #f0f0f5;
  padding: 120px 0px 100px;
  background-image: url("./assets/img/press_Release.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20%) calc(100% - -15px);
  /* background-size: 28vw; */
}

.press-release section.head h2 {
  font-size: 30px;
}

.press-release section.content {
  padding: 60px 0;
}

.press-release .content ul li {
  cursor: pointer;
  padding: 15px 0px;
}

.press-release .content ul li:hover,
.press-release .content ul li.active {
  color: #31b5c4;
}

.press-release .content ul li:not(:last-child) {
  border-bottom: 1px solid #f5f6f7;
}

.press-release .content .right h1.main-heading {
  font-size: 25px;
  border-bottom: 1px solid #f5f6f7;
  padding-bottom: 23px;
  margin-bottom: 20px;
}

.press-release .content .right .address .details p {
  margin-bottom: 0;
}

.press-release .content .right .address .details p span {
  font-weight: 600;
}

.press-release .content .right .address .details .location,
.press-release .content .right .address .details .phone {
  margin-bottom: 10px;
}

.press-release .content .right .press-r .single {
  border: 1px solid #ece8eb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.press-release .content .right .press-r .single:hover {
  transform: translateY(-2px);
  /* box-shadow: 0 0 11px rgba(33, 33, 33, 0.2); */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.press-release .content .right .press-r .single .leftt {
  padding-left: 30px;
  padding-right: 30px;
}

.press-release .content .right .press-r .single img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.press-release .content .right .press-r .single .leftt .date {
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.press-release .content .right .press-r .single .leftt .date .source {
  font-weight: 600;
}
.press-release .content .right .press-r .single .leftt .date .dot {
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
  margin: 0px 15px;
}

.press-release .content .right .press-r .single .leftt .title {
  font-size: 20px;
  font-weight: 600;
}

.press-release .pagination-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
}

.press-release .pagination-button button {
  background: #f0f0f5;
  font-size: 15px;
  padding: 8px 20px;
}

.press-release.amarlab-press .details .image {
  margin-bottom: 20px;
}

.press-release.amarlab-press .details .date {
  font-size: 15px;
  margin-bottom: 10px;
}

.press-release.amarlab-press .details .title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.press-release.amarlab-press .details .description {
  margin-bottom: 15px;
}

.press-release.amarlab-press .details .share {
  display: flex;
}

.press-release.amarlab-press .details .share .single {
  margin-right: 10px;
}

/*=============================================================*/
/*========================== About ============================*/
/*=============================================================*/
.about {
  padding: 85px 0;
}

.about .title {
  font-weight: 600;
}
.about .subtitle {
  font-size: 1.4rem;
  font-weight: 600;
  color: #424446;
}

.about .subsubtitle {
  font-size: 1.25rem;
  font-weight: 500;
  /* color: #424446; */
}

.about ul {
  list-style-type: disc;
  padding-left: 40px;
}

.about ul ul {
  list-style-type: circle;
}

.about .bold-stat {
  font-weight: 600;
  font-style: italic;
}

.about p {
  font-size: 15px;
}

.about .details .card-header {
  background-color: rgba(0, 0, 0, 0.015);
}

.about .details .card-header button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  border: none;
  background: none;
}

.about .details .card-header button:focus {
  outline: none;
}

.about .details .card-header button p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}

.about .details .card-header button img {
  width: 9px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.about .details .card-header button img.active {
  transform: rotate(270deg);
}

.about .details .card-body p {
  font-size: 15px;
}

.about .details .card-body p:last-child {
  margin: 0;
}

.about .details .card-body ul li {
  font-size: 15px;
}

.about .accordion:not(:last-child) {
  margin-bottom: 20px;
}

/*=============================================================*/
/*========================= Checkout ==========================*/
/*=============================================================*/
.checkout {
  padding: 85px 0 200px 0;
}

.checkout input::placeholder,
.checkout textarea::placeholder {
  color: #1d1d1d;
  opacity: 0.25;
}

.checkout form.details {
  position: sticky;
  width: 100%;
  /* top: 6rem; */
}

.checkout .calculation {
  padding: 35px 35px 40px 35px;
  box-shadow: 0 3px 6px #73658645;
  position: sticky;
  top: 140px;
}

.checkout .calculation .order-summery h5,
.checkout .calculation .cart-summery h5 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 25px;
}

.checkout .calculation .order-summery {
  margin-bottom: 0;
}

.checkout .calculation .order-summery .list {
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.checkout .calculation .order-summery .list.load .spinner-border {
  border: 0.12em solid currentColor;
  border-right-color: transparent;
  width: 20px;
  height: 20px;
}

.checkout .calculation .order-summery .list p {
  margin-bottom: 0;
}

.checkout .mobile-first .calculation .order-summery .list p {
  font-weight: 600;
}

.checkout .mobile-first .calculation .order-summery .list.total {
  margin-bottom: 12px;
}

.checkout .calculation .order-summery .list .price {
  font-weight: 600;
}

.checkout .calculation .order-summery .list.total {
  font-weight: 600;
  margin-bottom: 20px;
}

.checkout .calculation .order-summery .break {
  height: 1.5px;
  width: 100%;
  background: #2d2d32;
  margin-bottom: 15px;
}

.checkout .calculation .order-summery .break.bottom {
  line-height: 2.5;
  height: 2px;
}

.checkout .calculation .order-summery .input-group {
  margin-bottom: 15px;
}

.checkout .calculation .order-summery .input-group input {
  border: none;
  outline: none;
  background: #f0f0f5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding-left: 18px;
  /* width: 200px; */
  height: 48px;
}

.checkout .calculation .order-summery .input-group button {
  margin-top: 0;
  border: none;
  outline: none;
  background: #e9e9ef;
  padding: 12px 16px;
  font-weight: 600;
  color: #1d1d1d;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.checkout .calculation .order-summery .banglalink {
  background: #f0f0f5;
  padding: 12px 17px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.checkout .calculation .order-summery .input-group button:hover {
  color: #1d1d1d;
}

.checkout .calculation .order-summery small {
  font-size: 14px;
  color: #787887;
}

.checkout .calculation .order-summery small a {
  font-weight: 600;
  color: #787887;
}

.checkout .calculation .order-summery button {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
}

.checkout .place-order-button {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
  padding-bottom: 15px;
}

.checkout .calculation .cart-summery .single .person {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.checkout .calculation .cart-summery .single .person .left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout .calculation .cart-summery .single .person .left img {
  height: 28px;
  margin-right: 12px;
}

.checkout .calculation .cart-summery .single .person .left .name {
  font-weight: 600;
  font-size: 14px;
  max-width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkout .calculation .cart-summery .single .person .left .age {
  font-size: 12px;
}

.checkout .calculation .cart-summery .single .person .right img {
  width: 11px;
  cursor: pointer;
}

.checkout .calculation .cart-summery .single .details {
  margin-bottom: 18px;
}

.checkout .calculation .cart-summery .single .details h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.checkout .calculation .cart-summery .single .details p {
  font-size: 12px;
  color: #787887;
  margin-bottom: 13px;
}

.checkout .calculation .cart-summery .single .details p span {
  font-weight: 600;
}

.checkout .calculation .cart-summery .single .details p.price {
  font-size: 15px;
  font-weight: 700;
  color: #2d2d32;
  margin-bottom: 15px;
}

.checkout .calculation .cart-summery .single .details p.type {
  color: #787887;
  font-weight: 600;
  font-size: 12px;
}

.checkout .calculation .cart-summery .single .details p.type span {
  background: #31b5c4;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
  text-transform: capitalize;
}

.checkout .calculation .cart-summery .single .details p.type span.package {
  background: #2f932f;
}

.checkout .calculation .cart-summery .single .hospital p {
  color: #787887;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
}

.checkout .calculation .cart-summery .single .hospital img {
  border: 1px solid #f0f0f5;
  padding: 9px 15px 10px 12px;
  border-radius: 5px;
  height: 71px;
}

.checkout .calculation .cart-summery .single {
  margin-top: 20px;
}

.checkout .calculation .cart-summery .single:not(:last-child) {
  border-bottom: 1px solid #f0f0f5;
  padding-bottom: 20px;
}

.checkout .calculation .cart-summery h5 {
  margin-bottom: 5px;
}

.checkout .details .main-heading {
  margin-bottom: 45px;
}

.checkout .details input {
  border: none;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  padding: 28px 30px;
  font-size: 18px;
  border-radius: 5px;
}

.checkout .details textarea {
  border: none;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  padding: 15px 25px;
  font-size: 18px;
  border-radius: 5px;
  margin-bottom: 11px;
  margin-top: 4px;
  height: 130px;
}

.checkout .details .time input {
  /* padding-right: 20px; */
  padding-right: 160px;
}
.MuiPickersToolbar-toolbar{
  background-color: #31b5c4 !important;
}
.MuiPickersDay-daySelected{
  background-color: #31b5c4 !important;
}
.MuiButton-textPrimary{
  color: #31b5c4 !important;
}
.MuiFormControl-marginNormal{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.MuiInput-underline:before{
  border-bottom: 0 !important;
}
.MuiInput-underline:after{
  border-bottom: 0 !important;
}
.MuiInputBase-input{
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.checkout .details .items.location select {
  border: none;
  outline: none;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  padding: 16px 27px;
  width: 100%;
  font-weight: 600;
  color: #1c1c1c;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 88%;
  background-position-y: 11px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}

.checkout .details .items .left h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.checkout .details .items .left p {
  color: #787887;
  margin-bottom: 0;
  font-size: 12px;
  margin-top: 5px;
}

.checkout .details .items {
  margin-bottom: 25px;
}

.checkout .details .items.location .sel-one {
  padding-right: 5px;
}

.checkout .details .items.location .sel-two {
  padding-left: 5px;
}

.checkout .details .items.delivery-copy {
  margin-bottom: 55px;
}

.checkout .details .items.delivery-copy select {
  border: none;
  outline: none;
  border: 1px solid #f0f0f5;
  border-radius: 5px;
  padding: 16px 27px;
  width: 100%;
  font-weight: 600;
  color: #1c1c1c;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 11px;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
}

.checkout .details .items.refrence .right textarea {
  margin: 0;
}

.checkout .details .amount .bg-primary {
  border-radius: 5px;
  padding: 30px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.checkout .details .amount .left {
  display: flex;
  align-items: center;
}

.checkout .details .amount .left h5 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 20px;
}

.checkout .details .amount .left .price {
  font-size: 37px;
  font-weight: 600;
  margin-bottom: 0;
}

.checkout .details .amount .right button {
  color: #fff;
  padding: 20px 60px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
}

.checkout .details .terms {
  font-size: 14px;
  color: #736586;
}

.checkout .details .terms a {
  font-weight: 600;
  color: #736586;
}
/*=============================================================*/
/*=========================== Cart ============================*/
/*=============================================================*/
.btn-golden {
  background-color: #edb853 !important;
  color: #fff;
}

.btn-golden:hover {
  background-color: #ddab4e !important;
  color: #fff;
}

.btn-golden,
.btn-golden:focus,
.btn-golden:focus-visible,
.btn-golden:disabled,
.btn-golden:active,
.btn-golden:hover {
  border-color: #edb853 !important;
  box-shadow: 0 0 0 0.1rem #edb85340;
}

.btn-purple {
  background-color: #434fb8 !important;
  color: #fff;
}

.btn-purple:hover {
  background-color: #434fb8 !important;
  color: #fff;
}

.btn-purple,
.btn-purple:focus,
.btn-purple:focus-visible,
.btn-purple:disabled,
.btn-purple:active,
.btn-purple:hover {
  border-color: #434fb8 !important;
  box-shadow: 0 0 0 0.1rem #434fb840;
}

.cart-carts {
  width: 308px;
  border-radius: 5px;
  position: absolute;
  top: 45px;
  right: 16px;
  z-index: 10000000;
  box-shadow: 0 3px 6px #cacad34d;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
}

.cart-carts.active {
  visibility: visible;
  opacity: 1;
}

.cart-carts .cart-header {
  background: #f0f0f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cart-carts .cart-header h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.cart-carts .cart-header button {
  font-size: 14px;
  font-weight: 600;
  color: #787887;
  border: 2px solid #cacad3;
  border-radius: 5px;
  padding: 8px 27px;
}

.cart-carts .details {
  padding: 24px 22px;
  background: #fff;
}

.cart-carts .details .single {
  border: 1.5px solid #f0f0f5;
  margin-bottom: 10px;
  border-bottom: none;
}

.cart-carts .details .single .head {
  padding: 15px;
  border: none;
  background: transparent;
  border-bottom: 1.5px solid #f0f0f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  outline: none;
}

.cart-carts .details .single .head img {
  height: 25px;
  margin-right: 12px;
}

.cart-carts .details .single .head .names {
  display: flex;
  align-items: center;
}

.cart-carts .details .single .head .names .name {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
  max-width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cart-carts .details .single .head .names .age {
  margin-bottom: 0;
  font-size: 12px;
  margin-left: 3px;
}

.cart-carts .details .single .head button {
  border: none;
  outline: none;
  background: transparent;
  height: 20px;
  align-self: flex-start;
}

.cart-carts .details .single .head .cross img {
  transform: rotate(90deg);
  width: 7px;
  height: auto;
  transition: transform 0.3s ease-in-out;
  margin-right: 0;
}

.cart-carts .details .single .head .cross img.active {
  transform: rotate(270deg);
}

.cart-carts .details .single .body {
  padding: 15px;
  border-bottom: 1px solid #f0f0f5;
}

.cart-carts .details .single .body h5 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cart-carts .details .single .body h5 img {
  height: 12px;
  padding: 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cart-carts .details .single .body p {
  font-size: 12px;
  color: #787887;
  margin-bottom: 13px;
}

.cart-carts .details .single .body p.pres {
  margin-bottom: 0;
  font-weight: 600;
}

.cart-carts .details .single .body .cash {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 18px;
}

.cart-carts .details .single .body p.type {
  color: #787887;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 18px;
}

.cart-carts .details .single .body p.type span {
  background: #31b5c4;
  color: #fff;
  border-radius: 5px;
  padding: 3px 10px;
  /* margin-left: 5px; */
  text-transform: capitalize;
}

.cart-carts .details .single .body p.type span.package {
  background: #2f932f;
}

.cart-carts .details .single .body .ordered {
  font-size: 12px;
  font-weight: 600;
  color: #787887;
  margin-bottom: 8px;
}

.cart-carts .details .single .body img {
  height: 71px;
  padding: 9px 15px 10px 12px;
  border: 1.5px solid #f0f0f5;
  border-radius: 5px;
}

.cart-carts .count {
  background-color: #f0f0f5;
  padding: 22px;
}

.cart-carts .count .bg-primary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 16px 22px;
  border-radius: 10px;
  font-size: 14px;
}

.cart-carts .count .left {
  display: flex;
  align-items: center;
  position: relative;
}

.cart-carts .count .left::after {
  content: " ";
  position: absolute;
  border-left: 1px #fff solid;
  top: -11%;
  right: -29px;
  height: 120%;
  margin-top: auto;
  margin-bottom: auto;
}

.cart-carts .count .left img {
  margin-right: 13px;
  width: 16px;
}

.cart-carts .count .left p span {
  font-weight: 700;
}

.cart-carts .count .right {
  font-weight: 600;
}

.cart-carts .button {
  padding: 22px 25px;
  background: #fff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cart-carts .button a {
  font-size: 14px;
  font-weight: 600;
  background-color: #2f932f;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #fff;
}

.cart-carts .button button {
  font-size: 14px;
  font-weight: 600;
  background-color: #2f932f;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #fff;
}

/*========================= Carousel ==========================*/
.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow--left {
  /* right: calc(1% + 1px); */
  top: 32%;
}

.react-multiple-carousel__arrow::before {
  color: #000;
}

.react-multiple-carousel__arrow,
.react-multiple-carousel__arrow:hover {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

/*========================= Date Picker ==========================*/
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: inline-block;
  width: 30px;
  line-height: 1.7rem;
  text-align: center;
  margin: 3px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0;
  /* background-color: #31b5c4; */
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
  /* background-color: #31b5c4; */
}

/*========================= Back to top ==========================*/
.back-to-top {
  position: fixed;
  bottom: 100px;
  background: #31b5c4;
  right: 32px;
  padding: 18px 22px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
}
.back-to-top i {
  color: #fff;
  font-size: 20px;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*=============================================================*/
/*==================== App Recomendation ======================*/
/*=============================================================*/
/* .modal {
  z-index: 2147483648 !important;
} */

.app-modal.modal-dialog {
  margin: 0;
}

.app-modal.modal-dialog.modal-dialog-centered {
  align-items: flex-end;
  min-height: calc(100% - 0px);
}

.app-recomendation p {
  font-size: 12px;
  text-align: center;
}

.app-recomendation p.head {
  border-bottom: 1px solid #dbdbdb;
  margin-top: 15px;
  padding-bottom: 10px;
  margin-bottom: 0;
}

.app-recomendation .body {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.app-recomendation .body .left {
  display: flex;
  align-items: center;
}

.app-recomendation .body .left p.app {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  margin-left: 5px;
}

.app-recomendation .body img {
  height: 40px;
}

.app-recomendation .body .right button {
  font-size: 12px;
  padding: 9px 24px 8px 25px;
}

.app-recomendation p.foot {
  margin-bottom: 15px;
  border-top: 1px solid #dbdbdb;
  padding-top: 10px;
  text-align: right;
  color: gray;
  padding-right: 15px;
}

/*=============================================================*/
/*========================== Modal ============================*/
/*=============================================================*/
.mbl-logsign-modal {
  padding: 30px;
}

.mbl-logsign-modal .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.mbl-logsign-modal .head h1 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.mbl-logsign-modal .head img {
  width: 14px;
  cursor: pointer;
}

.mbl-logsign-modal .head span {
  color: #31b5c4;
  text-transform: uppercase;
  margin-right: 27px;
  cursor: pointer;
}

.mbl-logsign-modal form input {
  display: block;
  background: #f0f0f5;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 100%;
  padding: 14px 22px;
  margin-bottom: 10px;
  color: #000;
}

.mbl-logsign-modal form input::placeholder {
  color: #bcbfcb;
}

.mbl-logsign-modal form .form-group {
  margin-bottom: 10px;
}

.mbl-logsign-modal form input.patient-dob {
  margin-bottom: 0;
}

.mbl-logsign-modal form .show-hide {
  position: relative;
}

.mbl-logsign-modal form .show-hide img {
  position: absolute;
  top: 14px;
  right: 6%;
  cursor: pointer;
}

.mbl-logsign-modal form .form-group.submittt {
  margin: 30px auto 20px auto;
}

.mbl-logsign-modal.lgin form .form-group.submittt {
  margin-top: 20px;
}

.mbl-logsign-modal.sgnp form .form-group.submittt {
  margin-bottom: 30px;
}

.mbl-logsign-modal.sgnp form.second .form-group.submittt {
  margin-bottom: 0;
}

.mbl-logsign-modal.sgnp form.third .form-group.submittt {
  margin-bottom: 0;
  margin-top: 20px;
}

.mbl-logsign-modal form .submittt button {
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.mbl-logsign-modal .body .bottom p {
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
}

.mbl-logsign-modal .body .bottom p a,
.mbl-logsign-modal .body .bottom p span {
  font-weight: 600;
}

.mbl-logsign-modal form p.para {
  color: #787887;
  font-size: 12px;
  margin-bottom: 30px;
  text-align: center;
  margin-top: 16px;
}

.mbl-logsign-modal form select.sex {
  display: block;
  background: #f0f0f5;
  font-size: 16px;
  border: none;
  outline: none;
  border-radius: 5px;
  width: 100%;
  margin: auto;
  padding: 14px 18px;
  margin-bottom: 10px;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 35px;
}

/*======================= Loading Gif =========================*/
.loading-gif {
  width: 100%;
}
.loading-gif img {
  display: block;
  margin: 0 auto;
  width: 40px;
}

.loading-gif p {
  padding: 0 !important;
  text-align: center;
  color: #31b5c4;
}

.loading-gif.disppear {
  animation: vanish 1s forwards;
}

@keyframes vanish {
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/*=============================================================*/
/*====================== Media Queries ========================*/
/*=============================================================*/
@media only screen and (max-width: 767px) {
  .card {
    border: 1px solid #dbdcdf;
  }
  .showcase {
    padding-top: 27px;
    padding-bottom: 50px;
    /* background-image: url('./assets/img/homepage-banner.png'); */
    background-image: url("./assets/img/GirlPhone.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 20%) calc(100% - 0px);
    /* background-size: 100%;
    background-position-x: 135px; */
    background-size: 127px;
    background-position-x: 100%;
  }

  .showcase h5 {
    font-size: 20px;
    margin-bottom: 6px;
  }

  .showcase p {
    margin-bottom: 0;
  }

  .showcase button,
  .showcase a {
    display: none;
  }

  .showcase h5 br {
    display: none;
  }
  .section-heading {
    margin-bottom: 15px;
    font-size: 16px;
  }

  .showcase h5.title-web,
  .showcase p.main-subheading.web {
    display: none;
  }

  .showcase h5.title-mobile br {
    display: initial;
  }

  .showcase p.main-subheading.mobile {
    margin-bottom: 0px;
  }

  .filter-mobile .buttons {
    border: 1px solid #dbdcdf;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .filter-mobile .buttons p {
    text-align: center;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    padding-top: 11px;
    padding-bottom: 11px;
    cursor: pointer;
  }

  .filter-mobile .buttons p img {
    height: 14px;
  }

  .filter-mobile .buttons .row .col-6:first-child {
    border-right: 1px solid #dbdcdf;
    padding-right: 0;
  }

  .filter-mobile .buttons .row .col-6:last-child {
    padding-left: 0;
  }

  .filter-Modal {
    padding: 30px;
  }

  .filter-Modal .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .filter-Modal .head .left {
    font-size: 18px;
    margin-bottom: 0;
    color: #1c1c1c;
    font-weight: 600;
  }

  .filter-Modal .head .right {
    display: flex;
    align-items: center;
  }

  .filter-Modal .head .right p {
    font-size: 16px;
    font-weight: 600;
    margin-right: 25px;
    margin-bottom: 0;
    color: #31b5c4;
  }

  .filter-Modal .head .right img {
    width: 14px;
    cursor: pointer;
  }

  .filter-Modal .button {
    margin-top: 20px;
  }

  .filter-Modal .button button {
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .filter-Modal .dropdowns select {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: #f0f0f5;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    padding: 14px 22px;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 94%;
    background-position-y: 0px;
    -moz-appearance: none;
    background-size: 36px;
    -webkit-appearance: none;
  }
}

@media only screen and (min-width: 768px) {
  .showcase h5.title-mobile,
  .showcase p.main-subheading.mobile {
    display: none;
  }

  .filter-mobile {
    display: none;
  }
}

/*========================== Navbar ===========================*/
@media only screen and (max-width: 767px) {
  /* nav.navbar .navbar-brand img {
    width: 25px;
  } */

  nav.navbar .cart img {
    height: 17px;
  }

  nav.navbar .cart img:first-child {
    margin-right: 20px;
  }

  nav.navbar .cart.mobile .user-det-mobile {
    height: 100% !important;
  }

  .navbar {
    padding: 12px 0;
  }

  .semi-nav {
    display: none;
  }

  .navbar .right .cart {
    margin-left: 20px;
  }

  .navbar .right .mobile-menu {
    transition: all 0.3s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .navbar .right .mobile-menu .button {
    width: 30px;
    height: 4px;
    background: #31b5c4;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
  }

  .navbar .right .mobile-menu .button::after,
  .navbar .right .mobile-menu .button::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 4px;
    background: #31b5c4;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
  }

  .navbar .right .mobile-menu .button::after {
    transform: translateY(9.5px);
  }

  .navbar .right .mobile-menu .button::before {
    transform: translateY(-9.5px);
  }
  /* navigation animation */
  .navbar .right .mobile-menu.active .button {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .navbar .right .mobile-menu.active .button::before {
    transform: rotate(45deg) translate(30px, -30px);
  }

  .navbar .right .mobile-menu.active .button::after {
    transform: rotate(-45deg) translate(30px, 30px);
  }

  nav.navbar .right .buttons .user-det ul,
  .navbar .right .location .dropdown .options-select ul {
    display: none;
  }

  nav.navbar .right .buttons .user-det ul.active,
  .navbar .right .location .dropdown .options-select ul.active {
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  nav.navbar .cart.mobile {
    display: none;
  }

  nav.navbar .navbar-brand a p {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .checkout .place-order-button {
    display: none;
  }
  nav.navbar .navbar-brand img {
    display: none;
  }
  nav.navbar .navbar-brand .mobile-logo{
    display: block;
    width: 100px;
  }

  nav.navbar .cart.desktop {
    display: none;
  }

  .dtl-top-cart.desktop {
    display: none;
  }
  .dtl-search{
    width: 200px;
  }

  .dt-page .description.search {
      padding-left: 0;
      padding-right: 0px;
  }

  .dt-page .input-group {
      left: -14px;
  }

  .dt-page .col-md-2.s {
      margin-right: 9px;
  }


  .dt-page .description.search{
    margin-bottom: 15px;
  }

  .dt-page .s-icon.btn-secondary {
    margin-left: 3px;
  }
  .test-details-faq-title {
    margin-right: 0% !important;
  }
  .details.diagnostic-dtls-accr {
    margin-right: 0% !important;
  }

  .navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
    margin-left: 15px;
    padding-right: 0;
    padding-left: 0;
    order: 1;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: none;
    width: 18px;
    height: 2px;
    background: #1c1c1c;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    position: relative;
  }
  .navbar-light .navbar-toggler-icon::after,
  .navbar-light .navbar-toggler-icon::before {
    content: "";
    position: absolute;
    width: 18px;
    height: 2px;
    background: #1c1c1c;
    border-radius: 25px;
    transition: all 0.3s ease-in-out;
    right: 0;
  }
  .navbar-light .navbar-toggler-icon::after {
    transform: translateY(5.5px);
  }
  .navbar-light .navbar-toggler-icon::before {
    transform: translateY(-5.5px);
  }
  /* nav.navbar .navbar-brand a {
    margin-left: 15px;
  } */
  .navbar-collapse {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px;
    order: 4;
    display: none;
  }
  nav.navbar .cart.mobile {
    order: 3;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  nav.navbar .cart.mobile .user-det-mobile {
    height: 40px;
  }

  nav.navbar .cart.mobile .user-det-mobile .after-login {
    height: 100%;
    background-color: #F0F0F5;
    border: 0;
    color: #31b5c4;
    font-size: 0.9rem;
    font-weight: 600;
  }

  nav.navbar .cart.mobile .badge.badge-danger {
    position: absolute;
    top: 4px;
    font-size: 9px;
    right: 12px;
  }
  nav.navbar .navbar-brand {
    margin-right: 0;
    order: 2;
  }
  nav.navbar .navbar-brand a p {
    margin-left: 10px;
  }
  nav.navbar .navbar-collapse .left {
    border-top: 1px solid #f0f0f5;
  }

  nav.navbar .right {
    display: none;
  }

  nav.navbar .right .buttons {
    margin: 0;
    margin-top: 10px;
  }
  .navbar .right .location {
    margin-top: 10px;
  }
  nav.navbar .navbar-nav a.active.first {
    margin-top: 12px;
  }

  nav.navbar .navbar-nav a {
    font-size: 12px;
  }
  .navbar .right .location .dropdown h5 {
    font-size: 12px;
  }
  .navbar .right .location .dropdown .options-select p {
    font-size: 12px;
  }
  .navbar .right .location .dropdown .options-select i.drop-icon {
    font-size: 12px;
    top: 4px;
    right: 15px;
  }
  .navbar .right .location .dropdown .options-select ul li {
    font-size: 12px;
  }

  nav.navbar .right .buttons .btn {
    padding-top: 5px;
    padding-bottom: 5px;
    width: 65px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .cart-carts .details.scroll {
    max-height: 300px;
    overflow-y: scroll;
  }

  .cart-carts .details.scroll::-webkit-scrollbar {
    width: 5px;
  }
  .cart-carts .details.scroll::-webkit-scrollbar-track {
    background: #f0f0f5;
  }
  .cart-carts .details.scroll::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }

  .mobile-semi-nav {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .polygon-icon {
    width: 18px;
    position: absolute;
    bottom: -10px;
    right: 49px;
    transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
  }

  .polygon-icon.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s;
  }

  .user-tab-mbl {
    /* order: 4; */
    width: 100%;
    position: absolute;
    top: 40px;
    transition: all 0.5s;
    visibility: hidden;
    opacity: 0;
  }

  .user-tab-mbl.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s;
    z-index: 1000;
  }

  .user-tab-mbl .container {
    display: block;
  }

  .user-tab-mbl .details {
    box-shadow: 0 2px 6px -2px #707070;
    background: #fff;
    border-radius: 5px;
    padding-top: 9px;
    padding-bottom: 30px;
  }

  .user-tab-mbl .details ul li:not(:last-child) {
    border-bottom: 1px solid #f0f0f5;
  }

  .user-tab-mbl .details ul li {
    padding: 11px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .user-tab-mbl .details ul li p {
    font-size: 17px;
    margin: 0;
  }

  .user-tab-mbl .details ul li img {
    width: 7px;
  }

  .user-tab-mbl .details .buttons {
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    margin-top: 19px;
  }

  .user-tab-mbl .details .buttons button,
  .user-tab-mbl .details .buttons a {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .user-tab-mbl .details .buttons button.signup {
    margin-right: 5px;
  }

  .user-tab-mbl .details .buttons button.login {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 992px) {
  .user-tab-mbl,
  .polygon-icon {
    display: none;
  }
}

/*========================= Homepage ==========================*/
@media only screen and (max-width: 767px) {
  .homepage .hero .right {
    display: none;
  }
  .homepage .hero .steps {
    flex-direction: column;
    margin-bottom: 20px;
    display: none !important;
  }
  .homepage .hero .buttons {
    display: none;
  }
  .homepage .hero .steps .one {
    margin-bottom: 20px;
    margin-right: 0;
  }
  .homepage .hero h5 {
    margin-bottom: 6px;
    font-size: 20px;
  }
  .homepage .hero p {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .homepage .hero .steps.second {
    margin-bottom: 25px;
  }
  .homepage .hero {
    padding-top: 27px;
    padding-bottom: 50px;
    /* background-image: url('./assets/img/homepage-banner.png'); */
    background-image: url("./assets/img/GirlPhone.png");
    background-repeat: no-repeat;
    background-position: calc(100% - 20%) calc(100% - 0px);
    /* background-size: 100%;
    background-position-x: 135px; */
    background-size: 127px;
    background-position-x: 100%;
    background-color: #f0f0f5;
    display: none;
  }
  .homepage .why-us.services {
    display: none;
  }
  .homepage .why-us.mobile {
    padding: 15px 0;
    display: block;
  }

  .homepage .why-us.mobile .row .col-4.first {
    padding-right: 5px;
    padding-left: 10px;
  }

  .homepage .why-us.mobile .row .col-4.second {
    padding-right: 5px;
    padding-left: 5px;
  }
  .homepage .why-us.mobile .row .col-4.third {
    padding-right: 10px;
    padding-left: 5px;
  }

  .homepage .why-us.mobile .single {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .homepage .why-us.mobile .single .icon img {
    height: 27px;
    margin-bottom: 8px;
  }
  .homepage .why-us.mobile .single .details h5 {
    font-size: 12px;
    margin-bottom: 3px;
    font-weight: 600;
  }
  .homepage .why-us.mobile .single .details p {
    font-size: 10px;
    margin: 0 auto;
    line-height: 1.4;
    max-width: 90px;
  }

  /* .homepage .why-us.mobile .single .details p:last-child {
    max-width: 90px;
  } */

  .homepage .order-card-container {
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .homepage .order-card-container .order-card-left {
    min-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .homepage .order-card-container .order-card-right {
    margin-left: 15px;
  }
  .homepage .order-card-container .order-card-right h5,
  .homepage .order-card-container .order-card-right p {
    width: 100%;
  }

  .homepage .order-find {
    display: block;
    padding: 25px 0;
  }

  .homepage .order-find .row .first {
    padding-right: 7.5px;
  }

  .homepage .order-find .row .second {
    padding-left: 7.5px;
  }

  .homepage .order-find .card {
    border: 1px solid #dbdcdf;
  }

  .homepage .order-find .card .card-body {
    padding: 10px 15px;
  }

  .homepage .order-find .card .card-body h5 {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  .homepage .order-find .card .card-body p {
    font-size: 10px;
    color: #787887;
    margin-bottom: 8px;
  }

  .homepage .order-find .card .card-body a {
    color: #31b5c4;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .homepage .order-find .card .card-body a img {
    width: 13px;
    margin-left: 10px;
  }

  .category {
    padding: 45px 0;
  }
  .homepage .category {
    padding: 20px 0;
  }
  .category .description {
    margin-bottom: 15px;
  }

  .test .abc .single {
    margin: 0 15px 0 0;
  }

  .react-multiple-carousel__arrow--right,
  .react-multiple-carousel__arrow--left {
    display: none;
  }

  .test .item.allergy {
    padding: 15px;
  }

  .test p.name {
    font-size: 12px;
  }

  .homepage .tests .description {
    margin-bottom: 15px;
    align-items: flex-start;
  }
  .homepage .tests .carous {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .homepage .tests .carous.mobile {
    margin-top: 10px;
  }
  .homepage .tests .description button,
  .homepage .tests .description a {
    padding: 9px 16px 8px;
    font-size: 12px;
    min-width: 92px;
  }
  .main-heading {
    margin-bottom: 4px !important;
    font-size: 16px;
  }
  .main-subheading {
    font-size: 12px;
  }
  .homepage .tests {
    padding: 25px 0;
    /* padding-top: 65px; */
  }
  .homepage .tests .card {
    margin-right: 5px;
    margin-left: 5px;
    border: 1px solid #dbdcdf;
  }
  .homepage .tests .card .card-body {
    padding: 12px 15px 15px 15px;
  }
  .homepage .tests .card .card-body h5 {
    font-size: 12px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .homepage .tests .card .card-body p.web {
    display: none;
  }
  .homepage .tests .card .card-body p {
    font-size: 10px;
    margin-bottom: 11px;
    min-height: 105px;
  }
  .homepage .tests .card .card-body p.mobile {
    min-height: 30px;
  }
  .homepage .tests .card .card-body p.price {
    margin-bottom: 15px;
    font-size: 12px;
  }
  .homepage .tests .card .card-body button {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .homepage .packages {
    padding: 20px 0;
  }
  .homepage .packages .description {
    margin-bottom: 15px;
    align-items: flex-start;
  }
  .homepage .packages .main-pac {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .homepage .packages .card {
    margin-right: 5px;
    margin-left: 5px;
  }
  .homepage .packages .description button,
  .homepage .packages .description a {
    padding: 9px 16px 8px;
    font-size: 12px;
    min-width: 92px;
  }
  .packages .card-img-top img {
    height: 140px;
  }
  .test-package .name {
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .test-package .details {
    font-size: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    min-height: 50px;
  }
  .test-package .footer .price {
    font-size: 12px;
  }
  .test-package .footer button {
    font-size: 12px;
    padding: 9px 14px 8px 16px;
  }
  .test-package .card-body img {
    width: 55px;
    padding: 5px 7px;
    border: 1px solid #dbdcdf;
  }
  .homepage .covid-19 {
    padding: 20px 0;
  }
  .homepage .blogs {
    padding: 25px 0 30px;
  }
  .homepage .blogs .description {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
  }
  .homepage .blogs .description h5 {
    margin-right: 0;
    margin-bottom: 5px;
    max-width: 100%;
  }
  .homepage .blogs .description p {
    margin-bottom: 13px;
    max-width: 100%;
    display: none;
  }
  .homepage .blogs .description a {
    /* margin-bottom: 15px; */
    /* width: 100%; */
    padding: 9px 16px;
    font-size: 12px;
    min-width: 135px;
    text-transform: uppercase;
  }
  .homepage .blogs .main-col {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
  .homepage .blogs .card {
    margin-right: 5px;
    margin-left: 5px;
  }
  .homepage .blogs .card .card-img-top {
    height: 125px;
  }
  .homepage .blogs .card-body {
    padding: 10px 15px 15px 15px;
    border-bottom: none;
  }
  .homepage .blogs .card-body h5 {
    font-size: 12px;
    margin-bottom: 8px;
    line-height: 1.8;
    font-weight: 600;
    height: 40px;
  }
  .homepage .blogs .card-body p {
    font-size: 10px;
    margin-bottom: 14px;
  }
  .homepage .blogs .card-body p.author {
    font-size: 10px;
  }
  .sponsor {
    padding: 50px 0 20px;
  }
  .sponsor h5.section-heading {
    font-size: 16px;
    margin-bottom: 0;
  }
  .sponsor img {
    height: 65px;
    margin: 0 25px;
  }
  .sponsor.featured img {
    height: 30px;
    margin: 0 25px;
  }
  .sponsor img.lalpaths {
    height: 85px;
  }
  .sponsor img.thyrocare {
    height: 105px;
  }

  .sponsor.featured img.daily-star {
    height: 30px;
  }

  .sponsor.featured img.dhaka-tribun {
    height: 20px;
  }

  .sponsor.featured img.new-adge {
    height: 20px;
  }

  .homepage .review {
    padding: 25px 0;
    background: #f0f0f5;
  }
  .homepage .review h5 {
    margin-bottom: 15px !important;
  }

  .homepage .review .single-item .details {
    font-size: 12px;
    margin-bottom: 18px;
  }
  .homepage .review .single-item .footer h5 {
    font-size: 14px;
    margin-bottom: 2px !important;
  }
  .homepage .review .single-item .footer p {
    font-size: 12px;
  }
  .homepage .review .single-item {
    height: 330px;
  }
  .homepage .counter {
    padding: 15px 0;
  }
  .homepage .counter h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .homepage .counter p {
    font-size: 12px;
  }

  .homepage .counter .first,
  .homepage .counter .second,
  .homepage .counter .third {
    padding: 15px 0;
  }

  .homepage .partners {
    padding: 30px 0 0px;
  }

  .homepage .partners .section-heading {
    margin-bottom: 4px;
  }

  .main-desc {
    font-size: 12px;
    margin-bottom: 15px;
  }

  .homepage .partners .main img {
    height: 40px;
  }

  .homepage .partners .single {
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .homepage .partners .logo-sec {
    margin-left: -5px;
    margin-right: -5px;
  }

  /* .homepage .partners .single:nth-child(even) {
    padding-right: 5px;
  }

  .homepage .partners .single:nth-child(odd) {
    padding-left: 5px;
  } */

  .global.req-order {
    margin: 10px 0;
    margin-top: 25px;
  }

  .global.req-order .container {
    background-position: calc(100% - 4%) calc(100% - 0px);
  }

  .global.req-order .container {
    padding: 25px 15px;
    background-size: 125px;
  }

  .global.req-order h5 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .global.req-order p {
    font-size: 12px;
    margin-bottom: 10px;
    /* width: 70%; */
  }

  .global.req-order form {
    display: flex;
  }

  .global.req-order input {
    font-size: 12px;
    width: auto;
    padding: 11px 15px;
    margin-right: 10px;
  }

  .global.req-order button {
    font-size: 12px;
    padding: 10px 20px;
  }

  .homepage .privilages {
    margin-top: 30px;
  }

  .homepage .privilages .section-heading {
    margin-bottom: 4px;
  }

  .homepage .privilages .one,
  .homepage .privilages .three {
    padding-right: 15px;
    margin-bottom: 15px;
  }

  .homepage .privilages .two,
  .homepage .privilages .four {
    padding-left: 15px;
    margin-bottom: 15px;
  }

  .homepage .privilages button {
    padding: 9px 25px;
    font-size: 12px;
  }

  .global.achievement {
    margin-top: 15px;
    padding: 20px 0;
  }

  .global.achievement .section-heading {
    margin-bottom: 4px;
  }

  .global.achievement .details p.content {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .global.achievement .details p.name {
    font-size: 14px;
    margin-bottom: 2px;
  }

  .global.achievement .details p.title {
    font-size: 12px;
  }

  .global.achievement .details .icon img {
    margin-top: 15px;
    width: 20px;
  }

  .global.achievement .container.carousel-container {
    padding-bottom: 30px;
  }

  .homepage .business-partner .img {
    height: 90px;
  }
  .homepage .business-partner img {
    width: 80px;
  }

  .homepage .business-partner .react-multiple-carousel__arrow--right,
  .homepage .business-partner .react-multiple-carousel__arrow--left {
    display: initial;
  }

  .homepage .business-partner .react-multiple-carousel__arrow--right,
  .homepage .business-partner .react-multiple-carousel__arrow--left {
    top: 33%;
  }

  .homepage .business-partner .react-multiple-carousel__arrow {
    min-width: 30px;
    min-height: 30px;
  }

  .homepage .business-partner .react-multiple-carousel__arrow::before {
    font-size: 15px;
  }

  .homepage .business-partner {
    padding-bottom: 25px;
    padding-top: 10px;
  }

  .homepage .blogs .description h5 {
    font-size: 16px;
    margin-right: 5px;
  }

  .global.faques h5 {
    margin-bottom: 4px;
  }

  .global.faques .accordion button p.head {
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
    text-align: left;
  }

  .global.faques .accordion button img {
    width: 15px;
    margin-left: 5px;
  }

  .global.faques .accordion div.desc {
    font-size: 12px;
    padding-left: 45px;
    padding-bottom: 10px;
    padding-right: 10px;
  }

  .global.faques .accordion div.desc p {
    font-size: 12px;
  }

  .global.faques .accordion div.desc p.brek {
    margin-bottom: 7px;
  }

  .global.faques .accordion .single {
    margin-bottom: 10px;
  }

  .global.faques .accordion button {
    padding: 10px;
  }

  .global.faques .accordion .active button {
    padding-bottom: 6px;
  }

  .global.faques {
    margin-bottom: 25px;
  }

  .homepage .order-sec .first,
  .homepage .order-sec .second {
    padding: 17px 17px;
    background-size: 150px;
  }

  /* .homepage .order-sec .second {
    background-size: 120px;
  } */

  .homepage .order-sec .first {
    margin-bottom: 10px;
  }

  .homepage .order-sec h5 {
    font-size: 13px;
    margin-bottom: 4px;
    width: 65%;
  }

  .homepage .order-sec p {
    font-size: 11.5px;
    margin-bottom: 0px;
    width: 65%;
    min-height: auto;
  }

  .homepage .order-sec a {
    font-size: 11.5px;
    padding: 9px 10px 8px 10px;
  }

  .homepage .featured .section-heading {
    margin-bottom: 4px;
  }

  .homepage .featured .image {
    width: 85px;
  }

  .homepage .featured {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .homepage .hero-mobile {
    display: none;
  }
  .category .carousel-sec {
    padding-left: 0;
    padding-right: 0;
  }
  .homepage .tests .card .card-body p.mobile {
    display: none;
  }

  .homepage .tests .carous.mobile {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  /* .homepage .tests .card {
    margin: 0;
  } */
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .homepage .hero .right .image {
    width: auto;
    height: auto;
  }
}

@media only screen and (max-width: 404px) {
  .homepage .hero .buttons .order {
    /* margin-right: 0; */
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 991px) {
  /* .homepage .blogs .card {
    margin: 0;
  } */

  .homepage .review .desktop {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .homepage .review .mobile {
    display: none;
  }

  .homepage .hero p.para br {
    display: none;
  }
}

@media only screen and (min-width: 450px) and (max-width: 991px) {
  .homepage .review .single-item {
    height: 350px;
  }
}

/*======================= Category =======================*/
@media only screen and (max-width: 767px) {
  .cate-page .title .lists {
    display: none;
  }
  .cate-page .all-labs .lists {
    display: none;
  }
  .cate-page .all-labs .diag-list .search {
    display: none;
  }

  .cate-page .all-labs .diag-list {
    padding-left: 15px;
  }

  .cate-page .all-labs .diag-list .pagi {
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .cate-page .title {
    display: none;
  }

  .cate-page .all-labs {
    padding: 30px 0;
  }

  .cate-page .filter-mobile .buttons {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cate-page .all-labs .diag-list .pagi .page-link {
    font-size: 12px;
  }

  .cate-page .all-labs .diag-list .pagi .count {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .cate-page .hero-mobile {
    display: none;
  }
}

/*======================= Call For Order =======================*/
@media only screen and (max-width: 767px) {
  .call-for-order {
    margin-bottom: 20px;
    padding: 10px 15px;
  }

  .call-for-order .left .icon,
  .call-for-order .left .text p {
    display: none;
  }

  .call-for-order .left .text h5 {
    font-size: 14px;
    margin-bottom: 0;
  }

  .call-for-order .right {
    border: 1px dashed #fff;
    padding: 5px 10px;
  }

  .call-for-order .right a {
    font-size: 12px;
  }

  .call-for-order .right a i {
    font-size: 12px;
    margin-right: 5px;
  }
}

/*======================== Diagnostic =========================*/
@media only screen and (max-width: 767px) {
  .checkups {
    padding-bottom: 50px;
  }
  .checkups .items .single-item .card {
    margin-bottom: 10px;
    border: 1px solid #dbdcdf;
  }
  .checkups .items .view-more button {
    margin-top: 15px;
    font-size: 12px;
    padding: 9px 25px;
    width: auto;
    background: #fff;
    border: 1px solid #dbdcdf;
  }
  .checkups .description {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .checkups .description form,
  .checkups .filter {
    display: none;
  }
  .services .col-md-4 {
    margin-bottom: 25px;
  }
  .checkups .filter .filters .left {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .checkups .filter .filters .left .categories,
  .checkups .filter .filters .left .labs {
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .checkups .filter select {
    width: 100% !important;
  }
  .checkups .filter .sort {
    flex-direction: column;
    align-items: flex-start;
  }
  .checkups .filter .sort p {
    margin-bottom: 10px !important;
  }
  .checkups .filter .filters .right {
    width: 100%;
  }
  .checkups .filter .border a {
    background: #31b5c4;
    color: #fff !important;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  .checkups .items .single-item:nth-child(odd) {
    padding-right: 5px;
  }
  .checkups .items .single-item:nth-child(even) {
    padding-left: 5px;
  }

  .checkups .items .single-item .card .card-body {
    padding: 11px 15px;
  }

  .checkups .items .single-item .card .card-body h5 {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .checkups .items .single-item .card .card-body p {
    font-size: 10px;
    min-height: 30px;
  }

  .checkups .items .single-item .card .card-body .places,
  .checkups .items .single-item .card .card-body .hospitals {
    display: none;
  }

  .checkups .items .single-item .card .card-body .footer {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .checkups .items .single-item .card .card-body .footer .price {
    font-size: 12px;
  }

  .checkups .items .single-item .card .card-body .footer button {
    font-size: 12px;
    padding-top: 9px;
    padding-bottom: 8px;
    margin-top: 15px;
    width: 100%;
  }

  .checkups .items .single-item .card .card-body p.web {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .checkups .items .single-item .card .card-body p.mobile {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .checkups .filter .border .rogw {
    flex-direction: column;
  }
  .checkups .filter .sort {
    margin-top: 10px;
    border: 0;
  }
  .checkups .filter .sort p {
    margin-left: 0;
  }
  .checkups .filter select {
    width: 150px;
    height: 43px;
    padding: 0px 15px;
    background-position-y: 4px;
  }
  .checkups .filter .filters {
    flex-direction: column;
    align-items: flex-start;
  }
  .checkups .filter .border {
    padding: 15px 22px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 400px) {
  .checkups .items .single-item .card .card-body .hospitals .name {
    padding: 14px 0px 13px 19px;
  }
}
@media only screen and (min-width: 401px) and (max-width: 430px) {
  .checkups .items .single-item .card .card-body .hospitals .name {
    padding: 15px 0px 15px 27px;
  }
}
@media only screen and (min-width: 431px) and (max-width: 463px) {
  .checkups .items .single-item .card .card-body .hospitals .name {
    padding: 16px 0px 16px 30px;
  }
}
@media only screen and (min-width: 464px) and (max-width: 1024px) {
  .checkups .items .single-item .card .card-body .hospitals .name {
    padding: 18px 0px 18px 30px;
  }
}
/*========================== Package ==========================*/
@media only screen and (max-width: 767px) {
  .test-package .card-body {
    padding: 15px;
  }
  .test-package .footer {
    padding: 7px 15px;
    border-top: 1px solid #dbdcdf;
  }
  .package-page .services .col-md-4 {
    margin-bottom: 20px;
  }
  .package-page .packages,
  .services {
    padding: 45px 0;
  }

  .partner-package .packages {
    padding-top: 25px;
  }

  .services {
    display: none;
  }

  .package-page .packages .descrip {
    margin-bottom: 15px;
  }

  .package-page .test-package .details {
    min-height: 50px;
  }

  .package-page .packages .item {
    margin-bottom: 15px;
  }

  .package-page .packages .btns button {
    font-size: 12px;
    width: auto;
    padding: 9px 25px;
    border: 1px solid #dbdcdf;
    background: #fff;
  }

  .package-page .services {
    display: none;
  }

  .package-page .sponsor {
    padding-top: 5px;
  }

  .package-page .sponsor h5.section-heading {
    text-align: start !important;
  }
}

/*=========================== Press ===========================*/
@media only screen and (min-width: 768px) {
  .press-release .content .left {
    padding-right: 30px;
  }
  .press-release .content .right {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .press-release .content .right .press-r .single {
    display: block;
    margin-bottom: 10px;
  }
  .press-release .content .right .press-r .single img {
    width: 100%;
  }
  .press-release .content .right .press-r .single .leftt {
    padding: 10px 15px;
  }
  .press-release section.head {
    background-position: calc(100% - 0%) calc(100% - 0px);
    background-size: 54vw;
    padding: 25px 0;
  }
  .press-release section.head h2 {
    font-size: 20px;
    margin-bottom: 6px;
    width: 180px;
  }
  .press-release section.head p {
    width: 180px;
  }
  .press-release section.content {
    padding: 25px 0;
  }
  .press-release .content ul li {
    padding: 5px 0px;
    font-size: 12px;
    font-weight: 600;
  }

  .press-release .content .right {
    margin-top: 15px;
  }

  .press-release .content .right h1.main-heading {
    font-size: 16px;
    border-bottom: none;
    padding-bottom: 7px;
  }

  .press-release .content .right p.not-found {
    font-size: 12px;
  }

  .press-release .content .right .press-r .single .leftt .title {
    font-size: 14px;
  }
  .press-release .content .right .press-r .single .leftt .date {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .press-release .content .right .address .details p {
    font-size: 12px;
  }

  .press-release .content .right .address .details .location,
  .press-release .content .right .address .details .phone {
    margin-bottom: 5px;
  }
  .press-release .content .right .address .details .email {
    margin-bottom: 10px;
  }

  .press-release.amarlab-press .details .title {
    font-size: 14px;
  }

  .press-release.amarlab-press .details .description,
  .press-release.amarlab-press .details .date {
    font-size: 12px;
  }
}

/*============================ FAQs ===========================*/
@media only screen and (max-width: 767px) {
  .faqs {
    padding: 40px 0;
  }

  .faqs .details {
    margin-top: 15px;
  }

  .faqs .details .card-header button p,
  .faqs .details .card-body p {
    font-size: 14px;
  }

  .faqs .details .card-header button img {
    width: 7px;
  }
  .faqs .details .card-header {
    padding: 8px;
  }

  .faqs .details .card-body {
    padding: 10px 13px;
  }
}

/*===================== Diagnostic Tests =======================*/
@media only screen and (max-width: 767px) {
  .diag-test-page .all-items {
    padding: 25px 0;
  }
  .diag-test-page .all-items .description {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .diag-test-page .all-items .description form {
    width: auto;
    margin-top: 10px;
  }
  .diag-test-page .all-items .items .card .card-body h5 {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .diag-test-page .all-items .items .card .card-body p {
    font-size: 10px;
    margin-bottom: 15px;
    min-height: 105px;
  }
  .diag-test-page .all-items .items .card .card-body button {
    font-size: 12px;
  }
  .diag-test-page .all-items .items .card {
    margin-bottom: 15px;
  }

  .diag-test-page .all-items .col-md-3.col-6:nth-child(odd) {
    padding-right: 7.5px;
  }
  .diag-test-page .all-items .col-md-3.col-6:nth-child(even) {
    padding-left: 7.5px;
  }

  .diag-test-page .all-items .pagination {
    margin-top: 15px;
  }
  .diag-test-page .all-items .pagination button {
    font-size: 12px;
  }

  .services .details h5 {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 12px;
  }

  .services .details p {
    font-size: 10px;
    margin-bottom: 0;
  }

  .services .icon img {
    height: 27px;
    width: auto;
  }

  .services .second .icon img {
    width: auto;
  }
  .services .icon {
    padding: 16px 20px;
  }

  .services .second .icon {
    padding: 16px 17px;
  }

  .services .col-first {
    padding-right: 7.5px;
  }
  .services .col-last {
    padding-left: 7.5px;
  }
  .services .second {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }

  .services .second .details p {
    max-width: 75px;
    margin: 0 auto;
  }

  .services-mobile {
    background-image: url("./assets/img/hero-img-3.jpg");
    padding: 25px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .services-mobile .start {
    background-color: #31b5c4f2;
    color: #fff;
    padding: 30px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .services-mobile .start .steps {
    margin-top: 25px;
  }

  .services-mobile .start .steps .single {
    display: flex;
    align-items: center;
  }

  .services-mobile .start .steps .single:not(:last-child) {
    margin-bottom: 18px;
  }

  .services-mobile .start .steps .single .right {
    margin-left: 13px;
  }

  .services-mobile .start .steps .single .right h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .services-mobile .start .steps .single .right p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .services-mobile .start .steps .single .left {
    background-color: #fff;
    padding: 13px 18.4px 15.8px 18.8px;
    border-radius: 50%;
  }

  .services-mobile .start .steps .single.second .left {
    padding: 13px 16.4px 15.8px 16.8px;
  }

  .services-mobile .start .steps .single .left img {
    height: 21px;
  }
}

@media only screen and (min-width: 768px) {
  .services-mobile {
    display: none;
  }
}

/*===================== Login / Sign Up =======================*/
@media only screen and (max-width: 767px) {
  .losign .socials button,
  .losign form input,
  .losign form button,
  .losign form .form-group .show-hide,
  .signup form .form-group.for-otp,
  .losign form input,
  .losign form select.sex {
    width: 100%;
  }

  .login .title,
  .signup .title {
    margin-bottom: 25px;
  }

  .losign .socials button.facebook {
    margin-bottom: 20px;
  }

  .losign {
    padding: 25px 0;
  }
  .signup form .form-group.for-otp input {
    margin-left: 0;
  }
  .signup .second form .form-group.for-otp button,
  .signup form .form-group.for-otp button {
    margin-right: 5px;
  }

  .losign .submit-button .loading-button {
    width: 100%;
  }

  /* .losign .loading-button {
    width: 100%;
  } */
}

/*========================= Profile ===========================*/
.profileInfo .age-rx input {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .all_patient {
    padding: 40px 0;
  }

  .all_patient .title.main-heading {
    margin-bottom: 15px !important;
  }

  .all_patient .navs .single p {
    font-size: 14px;
  }

  .all_patient .navs .single img {
    width: 6px;
  }

  .all_patient .navs .single:not(:last-child) {
    margin-bottom: 10px;
  }

  .all_patient .navs {
    margin-bottom: 30px;
  }

  .all_patient .navs .single {
    padding: 13px 23px;
    margin-bottom: 15px;
  }

  .all_patient .patients .single {
    padding: 7px 12px;
    margin-bottom: 10px;
  }

  .all_patient .add-another button {
    margin-top: 5px;
  }

  .all_patient .patients .single .name-sex h5 {
    margin-bottom: 3px;
    font-size: 14px;
  }

  .all_patient .patients .single p {
    font-size: 12px;
  }

  .all_patient .patients .single .name-sex i {
    font-size: 15px;
  }

  .all_patient .signup .title {
    margin-bottom: 0;
    text-align: left;
    font-weight: normal;
    font-size: 16px;
  }

  .all_patient .signup .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .all_patient form .name-gender .name .nainp,
  .all_patient form .name-gender .gender .nainp,
  .all_patient form .age-rx .react-datepicker-wrapper .react-datepicker__input-container input {
    padding: 13px 22px;
    font-size: 14px;
    width: 100%;
    margin-bottom: 15px;
  }

  .all_patient form .name-gender,
  .all_patient form .age-rx {
    margin-bottom: 0;
  }

  .all_patient form .name-gender {
    display: flex;
    flex-direction: column;
  }
  .all_patient form .name-gender .name,
  .all_patient form .name-gender .gender {
    width: 100%;
  }
}

/*========================= Payment ===========================*/
@media only screen and (max-width: 767px) {
  .payment-status .card {
    width: 100%;
  }
  .payment-status .card {
    padding: 25px;
  }
  .payment-status .card {
    margin-top: 25px;
  }
}

/*=========================== Order ===========================*/
@media only screen and (max-width: 767px) {
  .order-history {
    padding-top: 45px;
    padding-bottom: 75px;
  }
  .order-history h5.main-heading {
    margin-bottom: 20px;
  }
  .order-history .single {
    padding: 22px;
  }
  .order-history .single .header,
  .order-history .single .footer {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-history .single .footer .right a {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .order-history .single .footer .right a.dnld {
    width: 100%;
  }
  .order-history .single .footer .right {
    width: 100%;
  }
  .order-history .single .header .left .id {
    margin-bottom: 0;
  }
  .order-history .single .header {
    margin-bottom: 3px;
  }

  .order-place-details {
    padding-top: 25px;
    padding-bottom: 40px;
  }
  .order-place-details .left h5.main-heading {
    margin-bottom: 10px;
  }
  .order-place-details .left p.id {
    margin-bottom: 15px;
    font-size: 14px;
  }
  .order-place-details .left p.date {
    margin-bottom: 10px;
  }
  .order-place-details .left p.date.report-first {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-place-details .left p.date.report-first div {
    margin-bottom: 0px;
  }
  .order-place-details .left .cart-summery {
    margin: 25px 0 15px;
  }
  .order-place-details .left .cart-summery h5 {
    font-size: 16px;
    font-weight: 700;
  }
  .order-place-details .available-reports .cart-summery {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-place-details .available-reports .cart-summery h5 {
    margin-bottom: 10px !important;
  }
  .order-place-details .available-reports .items .single .card .pdf {
    flex-direction: column;
    align-items: flex-start;
  }
  .order-place-details .available-reports .items .single .card .pdf .right button {
    margin-top: 10px;
  }
  .order-place-details .left .location {
    flex-direction: column;
    margin-bottom: 10px;
  }
  .order-place-details .left .location p.left {
    font-size: 14px;
  }
  .order-place-details .left p.mobile {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .order-place-details .left p.email {
    margin-bottom: 18px;
    font-size: 14px;
  }
  .order-place-details .left .location .right p.bottom {
    width: 100%;
    margin-top: 0;
    font-size: 14px;
  }
  .order-place-details .left .bottom {
    padding: 20px;
    flex-direction: column;
    margin-top: 35px;
  }
  .order-place-details .left .bottom h5 {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .order-place-details .left .bottom button {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .order-place-details .right .card .cost .price p {
    font-size: 14px;
  }
  .order-place-details .right .card .cost .price {
    margin-bottom: 10px;
  }

  .order-place-details .right .card h5.title {
    font-size: 16px;
    margin-bottom: 15px;
  }
  /* .order-place-details .right .card {
    padding: 25px;
    margin-top: 25px;
  } */
  .order-place-details.details .right .card .bottom {
    margin-top: 15px;
    font-size: 16px;
  }

  .summery_cart .order_summery {
    flex-direction: column;
  }

  .summery_cart .order_summery .lab-logo {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .summery_cart .order_summery .lists {
    width: 100%;
  }
}

/*=========================== Cart ============================*/
@media only screen and (max-width: 767px) {
  .cart-carts {
    width: 92%;
    /* right: initial; */
  }
  .cart-carts .details {
    max-height: 235px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 991px) {
  .cart-mobile .cart-mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
    transition: all 1s;
  }

  .cart-mobile .main-cart {
    display: block;
    position: fixed;
    top: 0;
    /* left: 0; */
    bottom: 0;
    right: -100%;
    z-index: 1050;
    overflow: hidden;
    outline: 0;
    background: #fff;
    /* box-shadow: 0px 0px 4px 0px #888888; */
    transition: all 0.7s;
    width: 308px;
  }

  .cart-mobile .main-cart .main-cart-diaglog {
    position: relative;
    background: #fff;
    height: 100%;
  }

  .cart-mobile .main-cart.active {
    /* top: 100px; */
    right: 0;
    transition: all 0.7s;
  }

  .cart-mobile .main-cart .main-cart-diaglog .empty {
    padding: 20px;
    height: 100%;
  }

  .cart-mobile .main-cart .main-cart-diaglog .empty .close-icon img {
    width: 14px;
    cursor: pointer;
  }

  .cart-mobile .main-cart .main-cart-diaglog .empty .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .cart-mobile .main-cart .main-cart-diaglog .empty .details img {
    width: 48px;
    margin-bottom: 16px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .empty .details p {
    font-size: 12px;
    color: #787887;
    text-align: center;
  }

  .cart-mobile .main-cart .main-cart-diaglog .empty .details a {
    font-size: 14px;
    font-weight: 600;
    margin-top: 20px;
    padding: 14px 46px;
    width: 195px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .headd {
    background: #f0f0f5;
    padding: 20px 0;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .headd p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .headd img {
    position: absolute;
    top: 25px;
    left: 22px;
    width: 14px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count {
    background-color: #f0f0f5;
    padding: 22px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count .bg-primary {
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 16px 22px;
    border-radius: 10px;
    font-size: 14px;
    border-radius: 5px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count .left {
    display: flex;
    align-items: center;
    position: relative;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count .left img {
    margin-right: 13px;
    width: 16px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count .left::after {
    content: " ";
    position: absolute;
    border-left: 1px solid #fff;
    top: -11%;
    right: -29px;
    height: 120%;
    margin-top: auto;
    margin-bottom: auto;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .count .right {
    font-size: 14px;
    font-weight: 600;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .button {
    padding: 19px 22px;
    background-color: #fff;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .button a {
    font-size: 14px;
    font-weight: 600;
    background-color: #2f932f;
    padding-top: 14px;
    padding-bottom: 13px;
    color: #fff;
    border-radius: 5px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .foot {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details {
    padding: 24px 22px;
    background: #fff;
    overflow: scroll;
    max-height: 50vh;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single {
    border: 1.5px solid #f0f0f5;
    margin-bottom: 10px;
    border-bottom: none;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head {
    padding: 15px;
    border: none;
    background: transparent;
    border-bottom: 1.5px solid #f0f0f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    outline: none;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head img {
    height: 25px;
    margin-right: 12px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head .names {
    display: flex;
    align-items: center;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head .names .name {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    max-width: 90px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head .names .age {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 3px;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head button {
    border: none;
    outline: none;
    background: transparent;
    height: 20px;
    align-self: flex-start;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head .cross img {
    transform: rotate(90deg);
    width: 7px;
    height: auto;
    transition: transform 0.3s ease-in-out;
    margin-right: 0;
  }

  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details .single .head .cross img.active {
    transform: rotate(270deg);
  }

  .cart-mobile .not-empty .details .single .body {
    padding: 15px;
    border-bottom: 1px solid #f0f0f5;
  }

  .cart-mobile .not-empty .details .single .body h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-mobile .not-empty .details .single .body h5 img {
    height: 12px;
    padding: 0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .cart-mobile .not-empty .details .single .body p {
    font-size: 12px;
    color: #787887;
    margin-bottom: 0;
  }

  .cart-mobile .not-empty .details .single .body p.pres {
    font-weight: 600;
  }

  .cart-mobile .not-empty .details .single .body .cash {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
    margin-top: 13px;
  }

  .cart-mobile .not-empty .details .single .body p.type {
    color: #787887;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 15px;
  }

  .cart-mobile .not-empty .details .single .body p.type span {
    background: #31b5c4;
    color: #fff;
    border-radius: 5px;
    padding: 3px 10px;
    /* margin-left: 5px; */
    text-transform: capitalize;
  }

  .cart-mobile .not-empty .details .single .body p.type span.package {
    background: #2f932f;
  }

  .cart-mobile .not-empty .details .single .body img {
    height: 71px;
    padding: 9px 15px 10px 12px;
    border: 1px solid #f0f0f5;
    border-radius: 5px;
  }
  .cart-mobile .not-empty .details .single .body .ordered {
    font-size: 12px;
    font-weight: 600;
    color: #787887;
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 992px) {
  .cart-mobile {
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
  .cart-mobile .main-cart .main-cart-diaglog .not-empty .details {
    max-height: 60vh;
  }
}

/*======================= Hamburg Nav =========================*/
@media only screen and (max-width: 991px) {
  .cart-mobile.mbl-hamburg .main-cart {
    left: -100%;
    right: 0;
  }

  .cart-mobile.mbl-hamburg .main-cart.active {
    left: 0;
  }

  .cart-mobile.mbl-hamburg .main-cart .headd img.close {
    width: 11px;
    cursor: pointer;
    opacity: 1;
  }

  .cart-mobile.mbl-hamburg .main-cart .headd {
    padding: 20px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
  }

  .cart-mobile.mbl-hamburg .main-cart .headd .logo {
    display: flex;
    align-items: center;
  }

  .cart-mobile.mbl-hamburg .main-cart .headd .logo span {
    margin-left: 5px;
    color: #31b5c4;
    font-weight: 600;
  }

  .cart-mobile.mbl-hamburg .main-cart .body {
    padding: 2px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 68px);
  }

  .cart-mobile.mbl-hamburg .main-cart .body ul li {
    /* background: #f4f4f4; */
    padding: 15px 0;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dbdcdf;
    margin: 1px 0;
  }

  .cart-mobile.mbl-hamburg .main-cart .body ul li img {
    height: 10px;
  }

  .cart-mobile.mbl-hamburg .main-cart .body .footer {
    padding: 20px 0;
  }

  .cart-mobile.mbl-hamburg .main-cart .body .footer p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }

  .cart-mobile.mbl-hamburg .main-cart .body .footer p:not(:last-child) {
    margin-bottom: 10px;
  }

  .cart-mobile.mbl-hamburg .main-cart .body .footer p span,
  .cart-mobile.mbl-hamburg .main-cart .body .footer p a {
    font-weight: 600;
  }
}

/*=========================== 404 =============================*/
@media only screen and (max-width: 767px) {
  section.not-found img.main {
    width: 100%;
  }
  section.not-found h1 {
    font-size: 30px;
  }
  section.not-found a {
    padding: 13px 20px;
    font-size: 18px;
    font-weight: 600;
  }
}

/*========================= Modal ==========================*/
@media only screen and (max-width: 767px) {
  .main-modl .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }

  .modal .diagnostic-select .head h5 {
    font-size: 16px;
  }

  .modal .diagnostic-select .head p {
    max-width: 80%;
  }

  .modal .diagnostic-select .head button {
    font-size: 12px;
    padding: 7px 20px;
  }

  .modal .diagnostic-select .head,
  .modal .diagnostic-select .bodies {
    padding: 15px;
  }

  .modal .diagnostic-select .bodies .details .left h5 {
    font-size: 12px;
  }

  .modal .diagnostic-select .bodies .details .left p {
    font-size: 10px;
  }

  .modal .diagnostic-select .bodies .details {
    align-items: flex-start;
  }

  .modal .diagnostic-select .bodies .details .right select {
    padding: 7px 15px;
    font-size: 12px;
    background-position-y: -2px;
    width: 85px;
  }

  .modal .diagnostic-select .bodies .details {
    margin-bottom: 10px;
  }

  .modal .diagnostic-select .bodies .lists .single .hospital .logo img {
    width: 45px;
    padding: 6px 9px;
  }

  .modal .diagnostic-select .bodies .lists .single .radio .checkmark {
    top: 27%;
    height: 20px;
    width: 20px;
  }

  .modal .diagnostic-select .bodies .lists .single .radio {
    padding-left: 30px;
    margin-bottom: 0;
  }

  .modal .diagnostic-select .bodies .lists .single .hospital .name {
    min-width: 130px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .modal .diagnostic-select .bodies .lists .single .hospital .name h5,
  .modal .diagnostic-select .bodies .lists .single .hospital .name p {
    font-size: 11px;
  }

  .modal .diagnostic-select .bodies .lists .single .price .new {
    min-width: 70px;
    font-size: 12px;
    margin-left: 10px;
  }

  .modal .diagnostic-select .foot button,
  .modal .diagnostic-select button.next-step {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
  }

  .modal .diagnostic-select.patient .head .descrip img {
    margin-right: 10px;
    height: 13px;
  }

  .modal .diagnostic-select.patient .bodies .details .left h5 {
    margin-bottom: 10px;
    font-size: 14px;
  }

  .modal .diagnostic-select.patient .bodies {
    padding-top: 15px;
    max-height: 330px;
    overflow-y: scroll;
    padding: 10px 15px;
  }

  .modal .diagnostic-select.patient .bodies .details {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  .modal .diagnostic-select .foot {
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal .diagnostic-select.patient .bodies .details .right {
    width: 100%;
  }

  .modal .diagnostic-select.patient .bodies .details .right .person {
    font-size: 12px;
    padding: 10px 18px 9px 42px;
    width: 50%;
  }

  .modal .diagnostic-select.patient .bodies .details .right .person.first {
    margin-right: 10px;
  }

  .modal .diagnostic-select.patient .bodies .details .right .person .checkmark {
    top: 10px;
    left: 17px;
    height: 18px;
    width: 18px;
  }

  .modal .diagnostic-select.family .bodies .members {
    margin-bottom: 10px;
  }

  .modal .diagnostic-select.family .bodies .members .individual .checkmark {
    height: 20px;
    width: 20px;
    top: 1px;
  }

  .modal .diagnostic-select.family .bodies .members .individual {
    padding-left: 30px;
    font-size: 14px;
  }

  .modal .diagnostic-select.family .bodies .add-another button {
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  .modal .diagnostic-select.patient .bodies form button {
    font-size: 12px;
    padding: 10px 15px;
    margin-bottom: 10px;
  }

  .modal .diagnostic-select.patient .bodies .name .nainp {
    padding: 10px 17px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .modal .diagnostic-select.patient .bodies .name h5 {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .modal .diagnostic-select.patient .bodies .name .gender .male-female .gender {
    font-size: 14px;
    padding-left: 25px;
  }

  .modal .diagnostic-select.patient .bodies .name .gender .male-female .gender .checkmark {
    top: 2px;
    height: 18px;
    width: 18px;
  }

  .modal .diagnostic-select.patient .bodies .age-rx .react-datepicker-wrapper .react-datepicker__input-container input {
    padding: 10px 17px;
    width: 100%;
    font-size: 14px;
  }

  .modal .diagnostic-select.patient .bodies .name {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .modal .diagnostic-select.patient .bodies .gender {
    margin-left: 10px;
  }

  .modal .diagnostic-select.patient .bodies .gender select {
    padding: 10px 17px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .modal .diagnostic-select .bodies .lists .single .price .old {
    min-width: 65px;
    font-size: 12px;
    margin-left: 10px;
  }

  .modal .diagnostic-select .bodies .lists .single .wever span {
    font-size: 12px;
    min-width: 65px;
  }

  .modal .diagnostic-select.patient .bodies {
    overflow-y: scroll;
  }
  .modal .diagnostic-select.patient .bodies .name .gender {
    padding-left: 6px;
  }

  .modal .diagnostic-select .bodies .lists {
    max-height: 210px;
  }
}

/*========================= Checkout ==========================*/
@media only screen and (max-width: 991px) {
  /* .checkout .details .items .left {
    margin-bottom: 8px;
  }

  .checkout .details .items .left p {
    margin-top: 2px;
  }

  .checkout .details .items.location .sel-one {
    padding-right: 15px;
    margin-bottom: 10px;
  }

  .checkout .details .items.location .sel-two {
    padding-left: 15px;
  }

  .checkout .details .amount .bg-primary {
    flex-direction: column;
  }
  .checkout .details .amount .right,
  .checkout .details .amount .right button {
    width: 100%;
    margin-top: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .checkout .details .amount .left {
    flex-direction: column;
  }
  .checkout .details .amount .left .price {
    font-size: 27px;
  }

  .checkout .details input {
    padding: 28px 20px;
  }

  .checkout .details .items.location select {
    padding: 16px 20px;
    background-position-x: 94%;
  }
   .checkout .details .time input {
    padding-right: 18px;
  }

  .checkout .calculation {
    margin-top: 25px;
  }

  .checkout .calculation {
    padding: 25px;
  }

  .checkout .details .amount .bg-primary {
    padding: 20px 25px;
  } */

   .checkout .details .time input {
    padding-right: 200px;
  }

  .checkout {
    padding: 25px 0 35px 0;
  }

  .checkout input::placeholder,
  .checkout textarea::placeholder {
    color: hsl(0, 0%, 60%);
  }

  .checkout .calculation .order-summery.web {
    display: none;
  }

  .checkout .calculation {
    padding: 20px;
    box-shadow: none;
    background: #f0f0f5;
    border-radius: 5px;
  }

  .checkout .mobile-first h5.summery {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .checkout .calculation .order-summery .list {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .checkout .calculation .order-summery .list.payable {
    margin-bottom: 20px;
  }

  .checkout .calculation .order-summery .break {
    margin-bottom: 12px;
    background: #dbdcdf;
    /* width: 113.8%;
    margin-left: -20px; */
    height: 0.5px;
  }

  .checkout .calculation .order-summery .break.bottom {
    height: 1px;
  }

  .checkout .calculation .order-summery {
    margin-bottom: 0;
  }

  .checkout .calculation .order-summery .input-group {
    margin-bottom: 0;
  }

  .checkout .calculation .order-summery .input-group input {
    background: #fff;
    font-size: 14px;
    border: 1px solid #dbdcdf;
    height: 46px;
    margin-right: 6.5px;
    border-radius: 5px;
  }

  .checkout .mobile-first .calculation .order-summery .list p.price {
    font-weight: 400;
  }

  .checkout .calculation .order-summery .input-group button {
    background: #fff;
    border-left: 1px solid #e9e9ef;
    font-size: 14px;
    border: 1px solid #dbdcdf;
    padding: 11px 16px;
    border-radius: 5px !important;
  }

  .checkout .mobile-first .mbl-banglalink {
    padding: 20px;
    box-shadow: none;
    background: #f0f0f5;
    margin-top: 10px;
    border-radius: 5px;
    font-size: 14px;
  }

  .checkout form.details {
    margin-top: 45px;
  }

  .checkout .details .main-heading.web {
    display: none;
  }

  .checkout .details .main-heading.mobile {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px !important;
  }

  .checkout .details .main-heading.mobile span {
    font-weight: initial;
  }

  .checkout .details .items .left h5 {
    font-size: 14px;
    font-weight: 400;
  }

  .checkout .details .items .left p {
    font-size: 10px;
    margin-bottom: 10px;
  }

  .checkout .details input {
    padding: 22px 15px;
    font-size: 14px;
  }

  .checkout .details .items.location .sel-one {
    padding-right: 5px;
  }

  .checkout .details .items.location .sel-two {
    padding-left: 5px;
  }

  .checkout .details .items {
    margin-bottom: 20px;
  }

  .checkout .details .items.location select {
    padding: 12px 15px;
    font-size: 14px;
    background-position-x: 95%;
    background-position-y: 6px;
    margin-bottom: 7px;
  }

  .checkout .details textarea {
    font-size: 14px;
    padding: 11px 15px;
    height: 130px;
  }

  .checkout .details .terms,
  .checkout .details .amount {
    display: none;
  }

  .checkout .details .items.delivery-copy select {
    padding: 12px 15px;
    font-size: 14px;
    background-position-x: 96%;
    background-position-y: 6px;
    margin-bottom: 7px;
  }

  .checkout .details .items.delivery-copy {
    margin-bottom: 25px;
  }

  .checkout .details .items.t-and-c {
    margin-bottom: 45px;
  }

  .checkout .details .items.t-and-c .left {
    display: flex;
    align-items: flex-start;
  }

  .checkout .details .items.t-and-c .left #TermsService {
    margin-right: 10px;
  }

  .checkout .details .items.t-and-c h5 a {
    font-weight: 600;
    line-height: 1.5;
  }

  .checkout .mbl-sticky {
    position: sticky;
    bottom: 5%;
    z-index: 999;
    font-size: 16px;
    padding: 14px 0;
    margin: 0 15px;
    margin-top: 15px;
  }

  .checkout .mbl-terms {
    padding: 0 15px 0 16px;
    font-size: 13px;
    display: flex !important;
  }

  .checkout .mbl-terms .form-check-input {
    position: relative;
    margin-top: 0;
    margin-left: 0;
  }

  .checkout .mbl-terms .terms-text {
    margin-left: 10px;
    padding-top: 1px;
  }

  .checkout .calculation .cart-summery .single:not(:last-child) {
    padding-bottom: 20px;
  }

  .checkout .calculation .cart-summery h5.title-web {
    display: none;
  }

  .checkout .calculation .cart-summery .single:first-child {
    margin-top: 0;
  }

  .checkout .calculation .cart-summery .single {
    margin-bottom: 10px;

  }
  .mbl-diag-title {
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: 600;
  }

  .checkout .calculation.diag-list {
    background: #fff;
    padding: 0;
  }

  .checkout .calculation .cart-summery .single {
    border: 1px solid #f0f0f5;
    padding: 12px 15px;
    background-color: #FFF;
    margin-top: 15px;
    border-radius: 5px;
  }

  .checkout .calculation .cart-summery .lists {
    padding: 15px;
    background-color: #f0f0f5;
    border-radius: 5px;
  }

}

@media only screen and (max-width: 767px) {
  .checkout .details .time input {
    padding-right: 330px;
  }

  }
  @media only screen and (max-width: 460px) {
  .checkout .details .time input {
    padding-right: 215px;
    padding-top: 13px !important;
    padding-bottom: 12px !important;
  }

}


@media only screen and (min-width: 992px) {
  .checkout .mobile-first {
    display: none;
  }

  .checkout .details .main-heading.mobile {
    display: none;
  }

  .checkout .mbl-sticky {
    display: none;
  }

  .mbl-diag-title {
    display: none;
  }
}

/*====================== Privacy Policy ========================*/
@media only screen and (max-width: 767px) {
  .privacy-policy {
    padding: 40px 0;
  }

  .privacy-policy p {
    font-size: 12px;
  }

  .privacy-policy p:not(:last-child) {
    margin-bottom: 25px;
  }

  .privacy-policy ol,
  .privacy-policy ul {
    padding-left: 1.7rem;
  }

  .company-doc img,
  .privacy-policy.company-doc p,
  .company-doc .title {
    width: 100%;
  }

  .privacy-policy.company-doc p {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .company-doc .title {
    font-size: 14px;
  }

  .company-doc .img-item {
    margin-top: 20px;
  }

}

/*========================== Footer ===========================*/
@media only screen and (max-width: 767px) {
  footer .details {
    padding: 25px 0;
    background: #f0f0f5;
    color: #1c1c1c !important;
  }
  footer .details .footer-logo {
    margin-bottom: 10px;
  }
  footer .details .footer-logo img {
    width: 65px;
  }
  footer .details .footer-logo img.web {
    display: none;
  }
  footer .details .footer-logo img.mobile {
    display: initial;
  }
  footer .details .footer-logo p.logo-name {
    font-size: 12px;
    letter-spacing: 1.9px;
  }
  footer .details .company-details h5 {
    margin-bottom: 5px;
    font-size: 12px;
    /* font-weight: 400; */
  }
  footer .details .company-details p {
    font-size: 12px;
  }
  footer .details .company-details p.email {
    margin-top: 10px;
  }
  footer .details ul.social-icons {
    margin-top: 10px;
  }
  footer .details .useful-links .account,
  footer .details .useful-links .about {
    margin-top: 18px;
    margin-bottom: 18px;
  }
  /* footer .details .useful-links ul li {
    line-height: 2.4;
  } */
  footer .details .useful-links ul li:not(:last-child) {
    margin-bottom: 3px;
  }

  footer .links .parent {
    flex-direction: column;
    align-items: flex-start;
  }

  footer .links .parent .left ul li {
    margin-right: 0;
  }
  footer .links .parent .left,
  footer .links .parent .left ul {
    width: 100%;
    display: none;
  }
  footer .links .parent .left ul {
    justify-content: space-between;
    margin-bottom: 5px;
  }

  footer .links .parent .left ul li a,
  footer .links .parent .right {
    font-size: 12px;
  }

  footer .details .useful-links {
    margin-top: 28px;
  }

  footer .details .useful-links h4 {
    font-size: 14px;
    margin-bottom: 4px;
  }
  footer .details .useful-links a {
    font-size: 12px;
    color: #1c1c1c !important;
  }

  footer .details ul.social-icons li a i {
    color: #31b5c4;
  }

  footer .links {
    background: #e1ecef;
    color: #000;
    padding-top: 12px;
    padding-bottom: 11px;
  }

  footer .links .parent .right a {
    color: #000;
  }
}

/*======================= back to top =========================*/
@media only screen and (max-width: 767px) {
  .back-to-top {
    bottom: 16%;
    right: 8.5%;
    padding: 16px 20px;
  }
}

.sc-fzozJi.iwcs-welcome-container {
  display: none;
}

/*=============================== Swal =================================*/

/* .swal-modal .swal-icon--warning {
  border-color: #D65600;
}

.swal-modal .swal-icon--warning .swal-icon--warning__body {
  background-color: #FF7214;
}

.swal-modal .swal-icon--warning .swal-icon--warning__body .swal-icon--warning__dot {
  background-color: #FF7214;
} */

.swal-modal .swal-text {
  color: #000e;
}
.swal-modal .swal-footer .swal-button-container .swal-button--cancel {
  color: #000e;
}

.swal-modal .swal-footer .swal-button-container .swal-button--text {
  color: #fff;
  background-color: #008cc4ec;
}

.video-container {
  position: relative;
  padding-bottom: 0;
  padding-top: 0px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.phone-details{
  width: 292px;
  height: 60px;
  border: 1px dashed #31B5C4;
  border-radius: 7px;
  opacity: 1;
  font: normal normal medium 20px/24px Rubik;
  letter-spacing: 0px;
  color: #31B5C4;
  padding: 18px;
  text-align: center;
  font-weight: bold;
}

.phone-details i{
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}


.title-gray{
  font: normal normal normal 18px/30px Rubik;
  letter-spacing: 0px;
  color: #787887;
  margin-bottom: 20px;
  display: block;
}

.d-details h5{
  margin-bottom: 5px !important;
}

.diagnostic-title{
  margin-bottom: 10px !important;
}
.diagnostic-title button{
  margin-top: 15px;
}
.test-details-faq-title{
  background: #F0F0F5 0% 0% no-repeat padding-box;
  font: normal normal 600 18px/44px Open Sans;
  letter-spacing: 0px;
  color: #1D1D1D;
  padding-left: 20px;
  margin-top: 50px;
  margin-right: 10%;
}

.lab-details {
    margin-top: 20px;
    margin-bottom: 50px;
}

.lab-details span {
  font: normal normal 600 14px/22px Open Sans;
  letter-spacing: 0px;
  color: #787887;
}


.main-sub-title{
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #1D1D1D;
}

.lab-image {
/*    width: 60px;
    height: 60px;*/
}

.lab-image img:hover{
  background: #eee;
}
.lab-image img{
    width: 72px;
    height: 72px;
    /* height: 99px; */
    margin-right: 15px;
    padding: 12px 17px;
    /* border: 1px solid #ccc; */
    border: 1px solid #F0F0F5;
    margin-bottom: 15px;
}
.lab-image{
  margin-top:7px;
}
.note-image img{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    padding: 10px;
    background: #EAF8F9;
    margin-right: 20px;
}

.note h5{
  font: normal normal 600 16px/22px Open Sans;
}
.note p{
  font: normal normal normal 16px/24px Open Sans;
  color: #787887;
}

.diagnostic-details-faq.card-header button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    outline: none;
    border: none;
    background: none;
}


.diagnostic-details-faq.card-header button img.active {
    transform: rotate(270deg);
}

.diagnostic-details-faq.card-header button img {
    width: 9px;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
}

.diagnostic-details-faq.card-header{
    padding: 16px 16px !important;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 0px solid rgba(0,0,0,0.125) !important;
}
.diagnostic-details-faq p {
  margin-bottom:0px;
}

.diagnostic-dtls-accr .card {
  border-right: 0px !important;
  border-left: 0px !important;
}
.diagnostic-dtls-accr .card-body p{
  font: normal normal normal 16px/26px Open Sans;
  letter-spacing: 0px;
  color: #787887;

}

.btn-primary.dtl-cart{
    font: normal normal 600 14px/17px Open Sans;
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 5px;
}
.details.diagnostic-dtls-accr{
  margin-right:10%;
}


@media only screen and (max-width: 991px) {
  .mbl-sticky.shadow-sm.test-dtls-stcky{
    width: 100%;
    padding: 0;
  }
  .mbl-sticky.shadow-sm.test-dtls-stcky a.btn{
    width: 100%;
  }

  .dt-page .col-lg-8.row{
    padding-left: 30px;
  }

}


.package .col-md-8.description.search{
  padding-left: 0px;
  margin-bottom:35px;
}
.package .package-top-des{
  margin-top: 45px;
  margin-bottom: 20px;
}
.package .test-details-faq-title{
  margin-bottom:22px;
}
.package .test-details-faq-title span{
    font-weight: 500;
}
.package img {
    max-height: 300px;
    max-width: 100%;
}
.col-md-12.description.search {
  padding-left:0;
  padding-right:0;
}

.pckg-desc-list{
  width: 85%;
}


.subtitle-member{
  margin-bottom: 5px !important;
}
.member-caption{
  margin-bottom: 10px !important;
  border-bottom: 1px solid #3cb5c4;
  padding-bottom: 5px;
  color: #787887;
  font-size:15px;
}

.refundul li{
  margin-bottom:12px !important;
  list-style: disc;
}

@media only screen and (max-width: 600px) {
  .team-mobile{
    padding-left: 50px;
    padding-right: 50px;
    text-align: center !important;
  }
   .team-mobile img{
      float: none !important;
      margin-right: 0px !important;
      margin-top: 50px !important;
      margin-bottom: 30px !important;
   }

}
